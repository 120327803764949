import React from 'react';
import { locale } from '../../../locale';
import geni18n from '../../../locale/i18n';
import Typography from '../../Typography';
import styles from './styles.module.sass';

const enURLs = geni18n('en');
const daURLs = geni18n('da');

export default ({ page }) => (
  <div className={styles.langSwitch}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
      width='24px'
      height='24px'
      className={styles.icon}>
      <path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1zm7.5 6h-3.1a13.5 13.5 0 0 0-1.2-3.4A9.1 9.1 0 0 1 19.5 7zM15 12a26.6 26.6 0 0 1-.2 3H9.2a26.6 26.6 0 0 1-.2-3 26.6 26.6 0 0 1 .2-3h5.6a26.6 26.6 0 0 1 .2 3zm-3 9c-.6 0-1.7-1.4-2.4-4h4.8c-.7 2.6-1.8 4-2.4 4zM9.6 7c.7-2.6 1.8-4 2.4-4s1.7 1.4 2.4 4zm-.8-3.4A13.5 13.5 0 0 0 7.6 7H4.5a9.1 9.1 0 0 1 4.3-3.4zM3.5 9h3.7c-.1 1-.2 2-.2 3s.1 2 .2 3H3.5a9.3 9.3 0 0 1 0-6zm1 8h3.1a13.5 13.5 0 0 0 1.2 3.4A9.1 9.1 0 0 1 4.5 17zm10.7 3.4a13.5 13.5 0 0 0 1.2-3.4h3.1a9.1 9.1 0 0 1-4.3 3.4zm5.3-5.4h-3.7c.1-1 .2-2 .2-3s-.1-2-.2-3h3.7a9.2 9.2 0 0 1 0 6z"/>
    </svg>
    <Typography className={styles.text}
      variant='subtitle1'
      weight='regular'
      color='highlight'>
      {locale.toUpperCase()}
    </Typography>
    <div className={styles.dropmenu}>
      <div className={styles.dropmenuItem}>
        <a href={`${enURLs('_url:_')}${enURLs(page)}`}
          target='_self'
          className={styles.anchor}>
          <Typography align='left'
            color='dark'
            variant='subtitle2'
            weight='regular'
            className={styles.anchorText}>
            English
          </Typography>
        </a>
      </div>
      <div className={styles.dropmenuItem}>
        <a href={`${daURLs('_url:_')}${daURLs(page)}`}
          target='_self'
          className={styles.anchor}>
          <Typography align='left'
            color='dark'
            variant='subtitle2'
            weight='regular'
            className={styles.anchorText}>
            Dansk
          </Typography>
        </a>
      </div>
    </div>
  </div>
);