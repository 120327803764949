import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'gatsby';
import Typography from '../../Typography';
import refmap from './refmap';
import styles from './styles.module.sass';

export default () => (
  <Grid xs={12} sm={12} md={7} lg={8}
    item container>
    {refmap.map(({ heading, pages }, i) => (
      <Grid key={i}
        item xs={6} sm={4} md={4} lg={i===4 ? 4 : 2} xl={i===4 ? 4 : 2}>
        <Typography variant='h5' color='white' weight='bold'
          className={styles.heading}
          align='left'>
          {heading}
        </Typography>
        {pages.map(({ name, url, external }, i) => !external
        ? (
            <Link to={url}
              key={i}
              className={styles.link}>
              <Typography variant='subtitle2' color='highlight' weight='light'
              className={styles.page}
                align='left'
                key={name}>
                {name}
              </Typography>
            </Link>
          )
        : (
            <a href={url}
              key={i}
              className={styles.link}>
              <Typography variant='subtitle2' color='highlight' weight='light'
              className={styles.page}
                align='left'
                key={name}>
                {name}
              </Typography>
            </a>
          ))}
      </Grid>
    ))}
  </Grid>
)