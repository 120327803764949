import en from './en/keys';
import da from './da/keys';

const translations = {
  en,
  da
}

export default locale => key => translations[locale]
  ? translations[locale][key]
    ? translations[locale][key]
    : translations.en[key]
      ? translations.en[key]
      : key
  : translations.en[key]
    ? translations.en[key]
    : key;