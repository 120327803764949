import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import Inline from './Inline';
import InlineSub from './Inline/Sub';
import Burger from './Burger';
import Meta from './Meta';
import Logo from './Logo';
import LangSwitch from './LangSwitch/index';
import styles from './styles.module.sass';
import root from './navmap/root';

export default ({
  page = '',
  subpage = '',
  url = '',
  menu = root,
  submenu,
  homeLink,
  isLanding = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <header className={clsx(
          styles.container,
          submenu ? styles.containerHasSub : ''
        )}>
        <Meta />
        <Logo isLanding={isLanding} homeLink={homeLink}/>
        { isLanding
          ? null
          : <div className={styles.langSwitchContainer}>
            <LangSwitch page={url}/>
          </div>
        }
        <div className={styles.burgerIconContainer}>
          <IconButton
            title='Main menu'
            onClick={open ? handleClose : handleClick}>
            <MenuIcon/>
          </IconButton>
        </div>
        <Inline items={menu}
          url={url}
          page={page}
          isLanding={isLanding}/>
      </header>
      {submenu
      ? <InlineSub items={submenu} page={subpage}/>
      : null}
      <Burger items={menu}
        subitems={submenu}
        page={page}
        open={open}
        subpage={subpage}
        onClose={handleClose}
        onClick={handleClick}/>
    </>
  );
}