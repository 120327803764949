import React from 'react';
import clsx from 'clsx';
import styles from './styles.module.sass';

export default () => (
  <svg className={clsx(
      styles.iconViewBox,
      styles.iconViewBoxFacebook
    )} viewBox="0 0 60.734 60.733">
	  <path d="M57.378,0.001H3.352C1.502,0.001,0,1.5,0,3.353v54.026c0,1.853,1.502,3.354,3.352,3.354h29.086V37.214h-7.914v-9.167h7.914   v-6.76c0-7.843,4.789-12.116,11.787-12.116c3.355,0,6.232,0.251,7.071,0.36v8.198l-4.854,0.002c-3.805,0-4.539,1.809-4.539,4.462   v5.851h9.078l-1.187,9.166h-7.892v23.52h15.475c1.852,0,3.355-1.503,3.355-3.351V3.351C60.731,1.5,59.23,0.001,57.378,0.001z"/>
  </svg>
)