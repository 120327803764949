import React from "react";
import PropTypes from "prop-types";
import Header from '../Header';
import Footer from '../Footer';
import Overlay from "../Overlay";
import "./layout-styles.css";
import styles from "./styles.module.sass";

const Layout = ({
  children,
  overlay = false,
  isLanding = false,
  header,
  subheader,
  page = '',
  subpage = '',
  url = '_url:404',
  homeLink='/#main',
}) => {
  return (
  <>
    {overlay ? <Overlay/> : null}
    <Header page={page}
      subpage={subpage}
      menu={header}
      url={url}
      homeLink={homeLink}
      submenu={subheader}
      isLanding={isLanding}/>
    <div className={styles.mainContainer}>
      <div style={{
          
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column'
        }}
        direction='column'
        justify='space-between'>
        <div style={{ flex: 2 }}>
          {subheader ? <div className={styles.subHeaderPadding}/> : null}
          {children}
        </div>
        <Footer isLanding={isLanding}/>
      </div>
    </div>
  </>
)};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
