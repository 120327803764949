import React from 'react';
import clsx from 'clsx';
import { Link as GatsbyLink } from 'gatsby';
import styles from './styles.module.sass';

const Obi = () => (
  <svg viewBox="0 20 700.4 200.53"
    width='100%'
    height='100%'>
    <path d="M95.5,3A109.15,109.15,0,0,0,36.6,31.9C23.3,44.8,14.9,58.6,9.4,76.5,6.7,85,6.6,86.6,6.6,104c0,17.2.2,19.1,2.7,27.3,11.9,38.7,43.1,65.5,84.3,72.3,11.7,1.9,30.8,1.5,42.1-1,41.1-8.9,72-38.6,81-78,2.5-11.1,2.3-33.7-.5-44.9-9.2-37.1-37-64.3-76.1-74.4C129.3,2.5,106.3,1.4,95.5,3Zm26.9,58.5c11.6,3,20.4,10.6,25.8,22.1,10.7,22.9,2.3,49.2-19.2,59.7-6,3-7.2,3.2-16.5,3.2-9.1,0-10.6-.3-16.5-3.1-8.7-4.1-15.4-11-19.9-20.3-3-6.4-3.6-8.8-3.9-16.4-1-19.3,6.3-33.9,20.6-41.5C102.7,59.9,111.8,58.8,122.4,61.5Z" transform="translate(-6.6 -2.26)"/>
    <path d="M236,103.4V201h59.3c46.8,0,61.2-.3,68.9-1.5,17.6-2.6,30-8.1,40.1-17.4,11.3-10.6,15.2-20.9,14.5-38.5-.3-9.2-.9-12-3-16.7-4.8-10.5-14.3-18.5-29-24.5l-7.7-3.1,7.2-3.4c9.9-4.7,20.5-15.1,23.8-23.4,2-5.1,2.3-7.8,2.4-18,0-11.5-.1-12.3-3.2-18.8-6.1-12.9-18.2-22-36-26.9-7.6-2.2-9.5-2.2-72.5-2.6L236,5.9ZM339.4,56.8c6.4,2.2,9.1,5.8,9.1,12.2s-2.7,10-9.1,12.2c-3.9,1.3-8.6,1.7-22.1,1.8H300V55h17.3C330.8,55.1,335.5,55.5,339.4,56.8Zm6.1,68.4c8,4.4,10.7,12.3,6.3,18.8s-7.7,7.5-31,7.8l-20.8.3V122.8l21.3.4C338.6,123.5,343,123.8,345.5,125.2Z" transform="translate(-6.6 -2.26)"/>
    <path d="M435,103.5V201h65V6H435Z" transform="translate(-6.6 -2.26)"/>
    <path d="M568,52V75H525v55h43v46h58V130h44V75H626V29H568Z" transform="translate(-6.6 -2.26)"/>
  </svg>
);

const Drive = () => (
  <svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 363.5 94.5"
    className={styles.landingLogo}>
    <defs>
      <linearGradient id="linear-gradient-drive" x1="1594.25" y1="-1098.93" x2="1592.63" y2="-1100.25" gradientTransform="matrix(90, 0, 0, -90, -143339.39, -98935.1)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#f63e87"/>
        <stop offset="1" stopColor="#f59c62"/>
      </linearGradient>
    </defs>
    <path d="M121.4,4.2h28a39,39,0,0,1,16.8,3.5,25.9,25.9,0,0,1,11.3,9.9,28.5,28.5,0,0,1,4,15.1,28.7,28.7,0,0,1-4,15.1,26.5,26.5,0,0,1-11.3,9.9,40.4,40.4,0,0,1-16.8,3.4h-28Zm27.2,42q6.3,0,9.9-3.6c2.5-2.3,3.7-5.7,3.7-10s-1.2-7.6-3.7-10-5.7-3.5-9.9-3.5h-8v27Z"/>
    <path d="M212.9,46.8h-5.2V61.1H188.5V4.2h27.4a33.8,33.8,0,0,1,13.7,2.6,19.9,19.9,0,0,1,12.2,18.9A19.8,19.8,0,0,1,239,36.3a18.8,18.8,0,0,1-8,7.3l12,17.5H222.6Zm7.6-26c-1.3-1.2-3.2-1.7-5.8-1.7h-7V32.3h7c2.6,0,4.5-.5,5.8-1.7a6.2,6.2,0,0,0,2-4.9,6,6,0,0,0-2-4.9Z"/>
    <path d="M249,4.2h19.2V61.1H249Z"/>
    <path d="M359.8,18.7V4.2H334L320.3,31.8V61.1h39.5V46.6H339.1V39.2h18V25.4h-18V18.7Z"/>
    <path d="M308.7,4.2,294.1,33.4V4.2H274.9V61.1h24.5L327.8,4.2Z"/>
    <rect className={styles.driveOne} x="1.4" y="2.2" width="90" height="90" rx="8"/>
    <g>
      <path className={styles.driveTwo} d="M79.6,44.4,73.1,40l.9-1.7a.6.6,0,0,1,.6-.3H78a2.8,2.8,0,0,0,2.1-1l1.2-1.3a1.6,1.6,0,0,0,.3-1.6,1.4,1.4,0,0,0-1.4-.9H76.7a5.4,5.4,0,0,0-4.8,2.9l-1,1.9-3.1-5.6c-2.5-4.6-2.7-7.2-20.5-7.2H45.8c-17.7,0-17.9,2.6-20.4,7.2L22.3,38l-1-1.9a5.5,5.5,0,0,0-4.9-2.9H12.9a1.3,1.3,0,0,0-1.3.9,1.4,1.4,0,0,0,.2,1.6L13.1,37a2.8,2.8,0,0,0,2.1,1h3.4a.6.6,0,0,1,.6.3l.9,1.7-6.5,4.4a5.8,5.8,0,0,0-2.1,5.1,114.6,114.6,0,0,0,1.8,11.8,3.6,3.6,0,0,0,1.6,2.2L17,64.9l.8,2.7a1.6,1.6,0,0,0,1.3,1.1,35.3,35.3,0,0,0,8.6.1,2.6,2.6,0,0,0,1.9-1.3,9.2,9.2,0,0,0,.9-2.3h1.3a1.7,1.7,0,0,0,1-.6L35,58.2a3.9,3.9,0,0,1,3.5-2.4H54.7a4,4,0,0,1,3.5,2.3l2.1,6.5a2,2,0,0,0,1.1.6h1.3a9.2,9.2,0,0,0,.9,2.3,2.6,2.6,0,0,0,1.9,1.3,35.3,35.3,0,0,0,8.6-.1,1.6,1.6,0,0,0,1.3-1.1,22.6,22.6,0,0,0,.7-2.7l2.2-1.4a3.6,3.6,0,0,0,1.6-2.2,114.6,114.6,0,0,0,1.8-11.8A5.8,5.8,0,0,0,79.6,44.4ZM32.7,51a1.1,1.1,0,0,1-1.2,1.1H20.6a2.3,2.3,0,0,1-2.3-2.3h0V48.6a1.1,1.1,0,0,1,1.1-1.1H30.3a2.3,2.3,0,0,1,2.4,2.3h0ZM26.5,39.6a1.4,1.4,0,0,1-1.4-1.4h0a1.9,1.9,0,0,1,.2-.7l2.1-3.8c2.3-4,2.4-6.4,18.5-6.4h1.4c16,0,16.2,2.4,18.5,6.4l2.1,3.8a1.5,1.5,0,0,1-.6,2H26.5ZM74.9,49.8a2.3,2.3,0,0,1-2.3,2.3h-11A1.1,1.1,0,0,1,60.5,51h0V49.8a2.3,2.3,0,0,1,2.3-2.3H73.7a1.1,1.1,0,0,1,1.2,1.1h0Z"/>
      <path className={styles.driveTwo} d="M54.6,57.5h-16a2.1,2.1,0,0,0-1.7,1.2l-1.6,4.9H57.9l-1.6-5A2.5,2.5,0,0,0,54.6,57.5Z"/>
    </g>
    <path d="M133.9,70.3a6.7,6.7,0,0,1,2.8,2.4,7,7,0,0,1,0,7.2,5.8,5.8,0,0,1-2.8,2.3,9.4,9.4,0,0,1-4.3.9h-3.8v5.1h-4.3V69.5h8.1A11,11,0,0,1,133.9,70.3Zm-1.6,8.4a3,3,0,0,0,1-2.4,2.7,2.7,0,0,0-1-2.4,4.2,4.2,0,0,0-2.9-.9h-3.6v6.5h3.6A4.8,4.8,0,0,0,132.3,78.7Z"/>
    <path d="M143.3,87.5a7.3,7.3,0,0,1-2.9-2.6,8.4,8.4,0,0,1-1-3.9,8,8,0,0,1,1-3.8,7.3,7.3,0,0,1,2.9-2.6,7.8,7.8,0,0,1,4.1-1,7.7,7.7,0,0,1,4,1,6.4,6.4,0,0,1,2.8,2.6,7,7,0,0,1,1.1,3.8,7.4,7.4,0,0,1-1.1,3.9,6.4,6.4,0,0,1-2.8,2.6,8.8,8.8,0,0,1-4,1A8.9,8.9,0,0,1,143.3,87.5Zm6.7-3.6a4.7,4.7,0,0,0,0-5.8,3.6,3.6,0,0,0-2.6-1.1,3.7,3.7,0,0,0-2.7,1.1,4.7,4.7,0,0,0,0,5.8,3.3,3.3,0,0,0,2.7,1.1A3.3,3.3,0,0,0,150,83.9Z"/>
    <path d="M180.8,73.8l-5.2,14.4h-4l-3.3-8.9L165,88.2h-4.1l-5.2-14.4h4l3.4,9.8,3.5-9.8h3.6l3.4,9.8,3.5-9.8Z"/>
    <path d="M196.4,82.2H185.5a3.4,3.4,0,0,0,1.4,2.1,4.6,4.6,0,0,0,2.7.8,6,6,0,0,0,2-.3,5.7,5.7,0,0,0,1.6-1.1l2.3,2.4a7.5,7.5,0,0,1-6,2.4,10.1,10.1,0,0,1-4.3-1,6.8,6.8,0,0,1-3.9-6.5,6.9,6.9,0,0,1,3.8-6.4,7.1,7.1,0,0,1,3.9-1,7.9,7.9,0,0,1,3.8.9,6.2,6.2,0,0,1,2.7,2.6,7.7,7.7,0,0,1,1,4A8.7,8.7,0,0,1,196.4,82.2Zm-9.8-4.6a3.6,3.6,0,0,0-1.2,2.2h7.1a3.3,3.3,0,0,0-1.1-2.2,4,4,0,0,0-4.8,0Z"/>
    <path d="M205.3,74.1a8.4,8.4,0,0,1,2.9-.5v3.9h-.9a3.7,3.7,0,0,0-2.8,1,4,4,0,0,0-1,3v6.8h-4.2V73.8h4v1.9A4.6,4.6,0,0,1,205.3,74.1Z"/>
    <path d="M224.6,82.2H213.7a3.4,3.4,0,0,0,1.4,2.1,4.8,4.8,0,0,0,2.7.8,5.6,5.6,0,0,0,2-.3,6.2,6.2,0,0,0,1.7-1.1l2.2,2.4a7.5,7.5,0,0,1-6,2.4,10.1,10.1,0,0,1-4.3-1,6.8,6.8,0,0,1-3.9-6.5,6.9,6.9,0,0,1,3.8-6.4,7.3,7.3,0,0,1,3.9-1,7.9,7.9,0,0,1,3.8.9,6.2,6.2,0,0,1,2.7,2.6,7.7,7.7,0,0,1,1,4A8.7,8.7,0,0,1,224.6,82.2Zm-9.8-4.6a3.1,3.1,0,0,0-1.1,2.2h7.1a4,4,0,0,0-1.2-2.2,3.3,3.3,0,0,0-2.4-.8A3.5,3.5,0,0,0,214.8,77.6Z"/>
    <path d="M242.2,68.4V88.2h-4V86.6a5.9,5.9,0,0,1-4.5,1.9,6.6,6.6,0,0,1-3.7-1,5.9,5.9,0,0,1-2.6-2.6,8.9,8.9,0,0,1,0-7.8,6.5,6.5,0,0,1,2.6-2.6,7.5,7.5,0,0,1,3.7-.9,5.6,5.6,0,0,1,4.3,1.7V68.4ZM237,83.9a4.4,4.4,0,0,0,0-5.8,3.6,3.6,0,0,0-5.2,0,4.4,4.4,0,0,0,0,5.8,3.6,3.6,0,0,0,5.2,0Z"/>
    <path d="M265.8,74.5a6.5,6.5,0,0,1,2.6,2.6,8.9,8.9,0,0,1,0,7.8,6.5,6.5,0,0,1-2.6,2.6,7.6,7.6,0,0,1-3.7,1,5.9,5.9,0,0,1-4.5-1.9v1.6h-4V68.4h4.2v6.9a5.8,5.8,0,0,1,4.3-1.7A7.5,7.5,0,0,1,265.8,74.5Zm-1.7,9.4a4.7,4.7,0,0,0,0-5.8,3.7,3.7,0,0,0-2.7-1.1,3.5,3.5,0,0,0-2.6,1.1,4.4,4.4,0,0,0,0,5.8,3.1,3.1,0,0,0,2.6,1.1A3.3,3.3,0,0,0,264.1,83.9Z"/>
    <path d="M286,73.8l-6.5,15.3a7.6,7.6,0,0,1-2.4,3.5,6.1,6.1,0,0,1-3.6,1.1,8.5,8.5,0,0,1-2.2-.4,4,4,0,0,1-1.8-1l1.5-2.9a2.7,2.7,0,0,0,1.2.6l1.2.3a2.9,2.9,0,0,0,1.4-.4,3.9,3.9,0,0,0,1-1.4v-.2l-6.2-14.5h4.3l4,9.8,4.1-9.8Z"/>
    <path d="M299.4,87.3a8.7,8.7,0,0,1-3.6-3.5,9.1,9.1,0,0,1-1.4-4.9,9.8,9.8,0,0,1,5-8.5,12.3,12.3,0,0,1,10.6,0,10.1,10.1,0,0,1,3.6,3.5,9.4,9.4,0,0,1,1.4,5,9.1,9.1,0,0,1-1.4,4.9,9.3,9.3,0,0,1-3.6,3.5,11.5,11.5,0,0,1-10.6,0Zm8.3-3.2a5.3,5.3,0,0,0,2.1-2.1,6.5,6.5,0,0,0,.8-3.1,6.2,6.2,0,0,0-.8-3.1,5,5,0,0,0-2.1-2.2,6.8,6.8,0,0,0-6,0,5,5,0,0,0-2.1,2.2,6.2,6.2,0,0,0-.8,3.1,6.5,6.5,0,0,0,.8,3.1,5.3,5.3,0,0,0,2.1,2.1,6,6,0,0,0,6,0Z"/>
    <path d="M334.5,80.2a4.8,4.8,0,0,1,.9,2.9,4.2,4.2,0,0,1-1.9,3.8,9.7,9.7,0,0,1-5.6,1.3h-9.7V69.5h9.2a9,9,0,0,1,5.3,1.3,4.3,4.3,0,0,1,1.8,3.6,4,4,0,0,1-.7,2.4,4.2,4.2,0,0,1-1.8,1.7A4.5,4.5,0,0,1,334.5,80.2Zm-11.9-7.4v4.4h4.3a4,4,0,0,0,2.4-.6,1.8,1.8,0,0,0,.8-1.7,1.7,1.7,0,0,0-.8-1.6,4.1,4.1,0,0,0-2.4-.5Zm7.6,11.6a1.9,1.9,0,0,0,.9-1.7c0-1.6-1.2-2.4-3.5-2.4h-5V85h5A4.5,4.5,0,0,0,330.2,84.4Z"/>
    <path d="M338.7,69.5h4.4V88.2h-4.4Z"/>
    <path d="M359.7,80.5h-4.6v4.6h-3.5V80.5H347V77.2h4.6V72.7h3.5v4.5h4.6Z"/>
  </svg>
);

const Fleet = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 363.5 94.5"
    className={styles.landingLogo}>
    <defs>
      <linearGradient id="linear-gradient-fleet" x1="1593.69" y1="-894.95" x2="1592.34" y2="-897.2" gradientTransform="matrix(90, 0, 0, -90, -143339.39, -80575.1)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#3ffbc8"/>
        <stop offset="1" stopColor="#207e64"/>
      </linearGradient>
    </defs>
    <rect className={styles.fleetOne} x="1.4" y="2.2" width="90" height="90" rx="8"/>
    <path className={styles.fleetTwo} d="M40.4,92.2,17,32.5a7.8,7.8,0,0,1-.4-2.1,5.8,5.8,0,0,1,5.9-5.9H91.4v8.4h-55l-.7.2a1.5,1.5,0,0,0-.6,2.1h0L47.8,56.6H91.4v3.1H49.6l19,32.5Z"/>
    <path d="M133.9,70.3a6.7,6.7,0,0,1,2.8,2.4,7,7,0,0,1,0,7.2,5.8,5.8,0,0,1-2.8,2.3,9.4,9.4,0,0,1-4.3.9h-3.8v5.1h-4.3V69.5h8.1A11,11,0,0,1,133.9,70.3Zm-1.6,8.4a3,3,0,0,0,1-2.4,2.7,2.7,0,0,0-1-2.4,4.2,4.2,0,0,0-2.9-.9h-3.6v6.5h3.6A4.8,4.8,0,0,0,132.3,78.7Z"/>
    <path d="M143.3,87.5a7.3,7.3,0,0,1-2.9-2.6,8.4,8.4,0,0,1-1-3.9,8,8,0,0,1,1-3.8,7.3,7.3,0,0,1,2.9-2.6,7.8,7.8,0,0,1,4.1-1,7.7,7.7,0,0,1,4,1,6.4,6.4,0,0,1,2.8,2.6,7,7,0,0,1,1.1,3.8,7.4,7.4,0,0,1-1.1,3.9,6.4,6.4,0,0,1-2.8,2.6,8.8,8.8,0,0,1-4,1A8.9,8.9,0,0,1,143.3,87.5Zm6.7-3.6a4.7,4.7,0,0,0,0-5.8,3.6,3.6,0,0,0-2.6-1.1,3.7,3.7,0,0,0-2.7,1.1,4.7,4.7,0,0,0,0,5.8,3.3,3.3,0,0,0,2.7,1.1A3.3,3.3,0,0,0,150,83.9Z"/>
    <path d="M180.8,73.8l-5.2,14.4h-4l-3.3-8.9L165,88.2h-4.1l-5.2-14.4h4l3.4,9.8,3.5-9.8h3.6l3.4,9.8,3.5-9.8Z"/>
    <path d="M196.4,82.2H185.5a3.4,3.4,0,0,0,1.4,2.1,4.6,4.6,0,0,0,2.7.8,6,6,0,0,0,2-.3,5.7,5.7,0,0,0,1.6-1.1l2.3,2.4a7.5,7.5,0,0,1-6,2.4,10.1,10.1,0,0,1-4.3-1,6.8,6.8,0,0,1-3.9-6.5,6.9,6.9,0,0,1,3.8-6.4,7.1,7.1,0,0,1,3.9-1,7.9,7.9,0,0,1,3.8.9,6.2,6.2,0,0,1,2.7,2.6,7.7,7.7,0,0,1,1,4A8.7,8.7,0,0,1,196.4,82.2Zm-9.8-4.6a3.6,3.6,0,0,0-1.2,2.2h7.1a3.3,3.3,0,0,0-1.1-2.2,4,4,0,0,0-4.8,0Z"/>
    <path d="M205.3,74.1a8.4,8.4,0,0,1,2.9-.5v3.9h-.9a3.7,3.7,0,0,0-2.8,1,4,4,0,0,0-1,3v6.8h-4.2V73.8h4v1.9A4.6,4.6,0,0,1,205.3,74.1Z"/>
    <path d="M224.6,82.2H213.7a3.4,3.4,0,0,0,1.4,2.1,4.8,4.8,0,0,0,2.7.8,5.6,5.6,0,0,0,2-.3,6.2,6.2,0,0,0,1.7-1.1l2.2,2.4a7.5,7.5,0,0,1-6,2.4,10.1,10.1,0,0,1-4.3-1,6.8,6.8,0,0,1-3.9-6.5,6.9,6.9,0,0,1,3.8-6.4,7.3,7.3,0,0,1,3.9-1,7.9,7.9,0,0,1,3.8.9,6.2,6.2,0,0,1,2.7,2.6,7.7,7.7,0,0,1,1,4A8.7,8.7,0,0,1,224.6,82.2Zm-9.8-4.6a3.1,3.1,0,0,0-1.1,2.2h7.1a4,4,0,0,0-1.2-2.2,3.3,3.3,0,0,0-2.4-.8A3.5,3.5,0,0,0,214.8,77.6Z"/>
    <path d="M242.2,68.4V88.2h-4V86.6a5.9,5.9,0,0,1-4.5,1.9,6.6,6.6,0,0,1-3.7-1,5.9,5.9,0,0,1-2.6-2.6,8.9,8.9,0,0,1,0-7.8,6.5,6.5,0,0,1,2.6-2.6,7.5,7.5,0,0,1,3.7-.9,5.6,5.6,0,0,1,4.3,1.7V68.4ZM237,83.9a4.4,4.4,0,0,0,0-5.8,3.6,3.6,0,0,0-5.2,0,4.4,4.4,0,0,0,0,5.8,3.6,3.6,0,0,0,5.2,0Z"/>
    <path d="M265.8,74.5a6.5,6.5,0,0,1,2.6,2.6,8.9,8.9,0,0,1,0,7.8,6.5,6.5,0,0,1-2.6,2.6,7.6,7.6,0,0,1-3.7,1,5.9,5.9,0,0,1-4.5-1.9v1.6h-4V68.4h4.2v6.9a5.8,5.8,0,0,1,4.3-1.7A7.5,7.5,0,0,1,265.8,74.5Zm-1.7,9.4a4.7,4.7,0,0,0,0-5.8,3.7,3.7,0,0,0-2.7-1.1,3.5,3.5,0,0,0-2.6,1.1,4.4,4.4,0,0,0,0,5.8,3.1,3.1,0,0,0,2.6,1.1A3.3,3.3,0,0,0,264.1,83.9Z"/>
    <path d="M286,73.8l-6.5,15.3a7.6,7.6,0,0,1-2.4,3.5,6.1,6.1,0,0,1-3.6,1.1,8.5,8.5,0,0,1-2.2-.4,4,4,0,0,1-1.8-1l1.5-2.9a2.7,2.7,0,0,0,1.2.6l1.2.3a2.9,2.9,0,0,0,1.4-.4,3.9,3.9,0,0,0,1-1.4v-.2l-6.2-14.5h4.3l4,9.8,4.1-9.8Z"/>
    <path d="M299.4,87.3a8.7,8.7,0,0,1-3.6-3.5,9.1,9.1,0,0,1-1.4-4.9,9.8,9.8,0,0,1,5-8.5,12.3,12.3,0,0,1,10.6,0,10.1,10.1,0,0,1,3.6,3.5,9.4,9.4,0,0,1,1.4,5,9.1,9.1,0,0,1-1.4,4.9,9.3,9.3,0,0,1-3.6,3.5,11.5,11.5,0,0,1-10.6,0Zm8.3-3.2a5.3,5.3,0,0,0,2.1-2.1,6.5,6.5,0,0,0,.8-3.1,6.2,6.2,0,0,0-.8-3.1,5,5,0,0,0-2.1-2.2,6.8,6.8,0,0,0-6,0,5,5,0,0,0-2.1,2.2,6.2,6.2,0,0,0-.8,3.1,6.5,6.5,0,0,0,.8,3.1,5.3,5.3,0,0,0,2.1,2.1,6,6,0,0,0,6,0Z"/>
    <path d="M334.5,80.2a4.8,4.8,0,0,1,.9,2.9,4.2,4.2,0,0,1-1.9,3.8,9.7,9.7,0,0,1-5.6,1.3h-9.7V69.5h9.2a9,9,0,0,1,5.3,1.3,4.3,4.3,0,0,1,1.8,3.6,4,4,0,0,1-.7,2.4,4.2,4.2,0,0,1-1.8,1.7A4.5,4.5,0,0,1,334.5,80.2Zm-11.9-7.4v4.4h4.3a4,4,0,0,0,2.4-.6,1.8,1.8,0,0,0,.8-1.7,1.7,1.7,0,0,0-.8-1.6,4.1,4.1,0,0,0-2.4-.5Zm7.6,11.6a1.9,1.9,0,0,0,.9-1.7c0-1.6-1.2-2.4-3.5-2.4h-5V85h5A4.5,4.5,0,0,0,330.2,84.4Z"/>
    <path d="M338.7,69.5h4.4V88.2h-4.4Z"/>
    <path d="M359.7,80.5h-4.6v4.6h-3.5V80.5H347V77.2h4.6V72.7h3.5v4.5h4.6Z"/>
    <path d="M140.6,18.6v9.7h19.7V42.7H140.6V60.9h-19V4.2h42V18.6Z"/>
    <path d="M170.2,4.2h19V46h20.5V60.9H170.2Z"/>
    <path d="M324.3,19H307.7V4.2h52.2V19H343.4V60.9H324.3Z"/>
    <path d="M254.8,46.4V60.9H215.5V4.2h39.3V18.6H234.2v6.7h18V39h-18v7.4Z"/>
    <path d="M301.1,46.4V60.9H261.7V4.2h39.4V18.6H280.5v6.7h18V39h-18v7.4Z"/>
  </svg>
);

const VC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 666 94.5"
    className={styles.landingLogo}>
    <defs>
      <clipPath id="clip-path-vc">
        <rect className={styles.vcOne} x="1.4" y="1.2" width="90" height="90" rx="7.8"/>
      </clipPath>
      <linearGradient id="linear-gradient-vc" x1="1595.86" y1="-664.1" x2="1596.73" y2="-662.97" gradientTransform="matrix(105.71, 0, 0, -105.71, -168689.87, -70104.62)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#1136a1"/>
        <stop offset="1" stopColor="#315bdb"/>
      </linearGradient>
    </defs>
    <g>
      <path d="M431.7,45.1V58.8H394.1V4.7h37.6V18.4H412v6.4h17.2V38H412v7.1Z"/>
    </g>
    <g>
      <path d="M662.3,45V58.8H624.6V4.7h37.7V18.4H642.5v6.3h17.2V37.9H642.5V45Z"/>
    </g>
    <g>
      <path d="M202,18.5V4.8H177.5L164.4,31V58.9H202V45.1H182.3V38h17.2V24.9H182.3V18.5Z"/>
    </g>
    <g>
      <path d="M153.4,4.8,139.5,32.5V4.8H121.2V58.9h23.4l27-54.1Z"/>
    </g>
    <g>
      <path d="M261.7,4.7V58.8H243.5V38.9H226.6V58.8H208.4V4.7h18.2V23.9h16.9V4.7Z"/>
    </g>
    <g>
      <path d="M270.5,4.7h18.3V58.8H270.5Z"/>
    </g>
    <g>
      <path d="M310.1,56.2a26,26,0,0,1-10.8-10,27.8,27.8,0,0,1-3.9-14.6,28.2,28.2,0,0,1,3.9-14.7,27.4,27.4,0,0,1,10.8-10,34.7,34.7,0,0,1,15.5-3.7,33.8,33.8,0,0,1,14,2.9,25.7,25.7,0,0,1,10.2,8L338.3,24.4C335,20.2,331,18,326.5,18a12.1,12.1,0,0,0-9.2,3.7,13.9,13.9,0,0,0-3.5,9.9c0,4.1,1.2,7.4,3.5,9.8a11.8,11.8,0,0,0,9.2,3.7c4.5,0,8.5-2.1,11.8-6.3L349.8,49a24.4,24.4,0,0,1-10.2,8,35.7,35.7,0,0,1-29.5-.8Z"/>
    </g>
    <g>
      <path d="M355.1,4.7h18.2v40h15.5V58.8H355.1Z"/>
    </g>
    <g>
      <path d="M500.3,44.5,495,39.7c-3.4,4.3-7.3,6.4-11.9,6.4a12.3,12.3,0,0,1-9.2-3.7c-2.3-2.4-3.4-5.7-3.4-9.8s1.1-7.5,3.4-9.9a12.3,12.3,0,0,1,9.2-3.7c4.6,0,8.5,2.1,11.9,6.4l11.5-10.3a26.6,26.6,0,0,0-10.2-8,34.6,34.6,0,0,0-14.1-2.9,33.3,33.3,0,0,0-15.5,3.6,27.8,27.8,0,0,0-10.7,10,27.3,27.3,0,0,0-3.9,14.7A27,27,0,0,0,456,47.2a25.8,25.8,0,0,0,10.7,10,32.1,32.1,0,0,0,15.5,3.6A33.9,33.9,0,0,0,494,58.9Z"/>
    </g>
    <g>
      <path d="M542.3,49.4H521.7l-3.6,9.4H499.5L523.2,4.7h17.9l23.7,54.1H545.9Zm-5-13.1L532,22.6l-5.3,13.6Z"/>
    </g>
    <g>
      <path d="M590.9,45.2h-5V58.8H567.7V4.7h26.1a32.3,32.3,0,0,1,13,2.4,18.8,18.8,0,0,1,11.6,18,19.1,19.1,0,0,1-2.6,10.1,18.1,18.1,0,0,1-7.6,6.9l11.4,16.7H600.1Zm7.3-24.8a8.3,8.3,0,0,0-5.6-1.7h-6.7V31.4h6.7a8.3,8.3,0,0,0,5.6-1.7,5.8,5.8,0,0,0,1.8-4.6A6.2,6.2,0,0,0,598.2,20.4Z"/>
    </g>
    <g className={styles.vcTwo}>
      <g>
        <g>
          <path className={styles.vcThree} d="M5.9-6.3H88.6A10.4,10.4,0,0,1,99,3.9h0V87A10.3,10.3,0,0,1,88.6,97.1H5.9A10.3,10.3,0,0,1-4.5,87h0V3.9A10.4,10.4,0,0,1,5.9-6.3Z"/>
        </g>
      </g>
    </g>
    <g>
      <path className={styles.vcFour} d="M61.2,70.1H61A37.8,37.8,0,0,1,42.3,41.6L41,29.6l20.3-7.3,20,7.3-1.2,12a32.6,32.6,0,0,1-3.7,12.2l-4.9-4.3a11.9,11.9,0,1,0-3.1,3.7L73.9,58a44.2,44.2,0,0,1-5.6,6.7A36.1,36.1,0,0,1,61.4,70h0l-.2.2ZM16.9,66.9a1.4,1.4,0,0,1-1.4-1.4V49.4a4.3,4.3,0,0,1,.7-2.5L18,44.3l-5.4-1.4a1.4,1.4,0,0,1-1-.8,1.4,1.4,0,0,1,.8-1.8h4.5a1.6,1.6,0,0,1,1.2.6l1.2,1.4,6.2-9.1a3.3,3.3,0,0,1,2.4-1.5l9.8-.7,1.2,10.3a23.4,23.4,0,0,0,.4,2.6H27.2l.6,1.5H39.6a41,41,0,0,0,2.7,8.6H35.1a2.6,2.6,0,0,0-2.5,1.6l-1.3,2.6a1.7,1.7,0,0,0,.8,2.1l.7.2h13a31.6,31.6,0,0,0,1.8,2.7H26.4v2.4A1.4,1.4,0,0,1,25,66.9Zm3.3-17.1a.8.8,0,0,0-.7.8v1.8a.8.8,0,0,0,.7.8h7.5a.7.7,0,0,0,.6-.3,1,1,0,0,0,.1-.9l-.6-1.4a1.3,1.3,0,0,0-1.1-.8Zm35,1.1a9.1,9.1,0,0,1-3-5.5h5.2a1.3,1.3,0,0,0,1.2-.8L59.8,42,62,48a1.5,1.5,0,0,0,.5.6l.7.2h.3l.8-.5,1.9-2.9h4A9.1,9.1,0,0,1,59.8,53,9.7,9.7,0,0,1,55.2,50.9Zm8.2-4.5h0L61,40.1a1.3,1.3,0,0,0-1.2-.8h-.5a1.3,1.3,0,0,0-.7.6l-1.5,3.6h-5a9.1,9.1,0,0,1,18.2,0H65.9a1.2,1.2,0,0,0-1,.5l-1.5,2.3Z"/>
    </g>
    <path d="M133.9,70.3a6.7,6.7,0,0,1,2.8,2.4,7,7,0,0,1,0,7.2,5.8,5.8,0,0,1-2.8,2.3,9.4,9.4,0,0,1-4.3.9h-3.8v5.1h-4.3V69.5h8.1A11,11,0,0,1,133.9,70.3Zm-1.6,8.4a3,3,0,0,0,1-2.4,2.7,2.7,0,0,0-1-2.4,4.2,4.2,0,0,0-2.9-.9h-3.6v6.5h3.6A4.8,4.8,0,0,0,132.3,78.7Z"/>
    <path d="M143.3,87.5a7.3,7.3,0,0,1-2.9-2.6,8.4,8.4,0,0,1-1-3.9,8,8,0,0,1,1-3.8,7.3,7.3,0,0,1,2.9-2.6,7.8,7.8,0,0,1,4.1-1,7.7,7.7,0,0,1,4,1,6.4,6.4,0,0,1,2.8,2.6,7,7,0,0,1,1.1,3.8,7.4,7.4,0,0,1-1.1,3.9,6.4,6.4,0,0,1-2.8,2.6,8.8,8.8,0,0,1-4,1A8.9,8.9,0,0,1,143.3,87.5Zm6.7-3.6a4.7,4.7,0,0,0,0-5.8,3.6,3.6,0,0,0-2.6-1.1,3.7,3.7,0,0,0-2.7,1.1,4.7,4.7,0,0,0,0,5.8,3.3,3.3,0,0,0,2.7,1.1A3.3,3.3,0,0,0,150,83.9Z"/>
    <path d="M180.8,73.8l-5.2,14.4h-4l-3.3-8.9L165,88.2h-4.1l-5.2-14.4h4l3.4,9.8,3.5-9.8h3.6l3.4,9.8,3.5-9.8Z"/>
    <path d="M196.4,82.2H185.5a3.4,3.4,0,0,0,1.4,2.1,4.6,4.6,0,0,0,2.7.8,6,6,0,0,0,2-.3,5.7,5.7,0,0,0,1.6-1.1l2.3,2.4a7.5,7.5,0,0,1-6,2.4,10.1,10.1,0,0,1-4.3-1,6.8,6.8,0,0,1-3.9-6.5,6.9,6.9,0,0,1,3.8-6.4,7.1,7.1,0,0,1,3.9-1,7.9,7.9,0,0,1,3.8.9,6.2,6.2,0,0,1,2.7,2.6,7.7,7.7,0,0,1,1,4A8.7,8.7,0,0,1,196.4,82.2Zm-9.8-4.6a3.6,3.6,0,0,0-1.2,2.2h7.1a3.3,3.3,0,0,0-1.1-2.2,4,4,0,0,0-4.8,0Z"/>
    <path d="M205.3,74.1a8.4,8.4,0,0,1,2.9-.5v3.9h-.9a3.7,3.7,0,0,0-2.8,1,4,4,0,0,0-1,3v6.8h-4.2V73.8h4v1.9A4.6,4.6,0,0,1,205.3,74.1Z"/>
    <path d="M224.6,82.2H213.7a3.4,3.4,0,0,0,1.4,2.1,4.8,4.8,0,0,0,2.7.8,5.6,5.6,0,0,0,2-.3,6.2,6.2,0,0,0,1.7-1.1l2.2,2.4a7.5,7.5,0,0,1-6,2.4,10.1,10.1,0,0,1-4.3-1,6.8,6.8,0,0,1-3.9-6.5,6.9,6.9,0,0,1,3.8-6.4,7.3,7.3,0,0,1,3.9-1,7.9,7.9,0,0,1,3.8.9,6.2,6.2,0,0,1,2.7,2.6,7.7,7.7,0,0,1,1,4A8.7,8.7,0,0,1,224.6,82.2Zm-9.8-4.6a3.1,3.1,0,0,0-1.1,2.2h7.1a4,4,0,0,0-1.2-2.2,3.3,3.3,0,0,0-2.4-.8A3.5,3.5,0,0,0,214.8,77.6Z"/>
    <path d="M242.2,68.4V88.2h-4V86.6a5.9,5.9,0,0,1-4.5,1.9,6.6,6.6,0,0,1-3.7-1,5.9,5.9,0,0,1-2.6-2.6,8.9,8.9,0,0,1,0-7.8,6.5,6.5,0,0,1,2.6-2.6,7.5,7.5,0,0,1,3.7-.9,5.6,5.6,0,0,1,4.3,1.7V68.4ZM237,83.9a4.4,4.4,0,0,0,0-5.8,3.6,3.6,0,0,0-5.2,0,4.4,4.4,0,0,0,0,5.8,3.6,3.6,0,0,0,5.2,0Z"/>
    <path d="M265.8,74.5a6.5,6.5,0,0,1,2.6,2.6,8.9,8.9,0,0,1,0,7.8,6.5,6.5,0,0,1-2.6,2.6,7.6,7.6,0,0,1-3.7,1,5.9,5.9,0,0,1-4.5-1.9v1.6h-4V68.4h4.2v6.9a5.8,5.8,0,0,1,4.3-1.7A7.5,7.5,0,0,1,265.8,74.5Zm-1.7,9.4a4.7,4.7,0,0,0,0-5.8,3.7,3.7,0,0,0-2.7-1.1,3.5,3.5,0,0,0-2.6,1.1,4.4,4.4,0,0,0,0,5.8,3.1,3.1,0,0,0,2.6,1.1A3.3,3.3,0,0,0,264.1,83.9Z"/>
    <path d="M286,73.8l-6.5,15.3a7.6,7.6,0,0,1-2.4,3.5,6.1,6.1,0,0,1-3.6,1.1,8.5,8.5,0,0,1-2.2-.4,4,4,0,0,1-1.8-1l1.5-2.9a2.7,2.7,0,0,0,1.2.6l1.2.3a2.9,2.9,0,0,0,1.4-.4,3.9,3.9,0,0,0,1-1.4v-.2l-6.2-14.5h4.3l4,9.8,4.1-9.8Z"/>
    <path d="M299.4,87.3a8.7,8.7,0,0,1-3.6-3.5,9.1,9.1,0,0,1-1.4-4.9,9.8,9.8,0,0,1,5-8.5,12.3,12.3,0,0,1,10.6,0,10.1,10.1,0,0,1,3.6,3.5,9.4,9.4,0,0,1,1.4,5,9.1,9.1,0,0,1-1.4,4.9,9.3,9.3,0,0,1-3.6,3.5,11.5,11.5,0,0,1-10.6,0Zm8.3-3.2a5.3,5.3,0,0,0,2.1-2.1,6.5,6.5,0,0,0,.8-3.1,6.2,6.2,0,0,0-.8-3.1,5,5,0,0,0-2.1-2.2,6.8,6.8,0,0,0-6,0,5,5,0,0,0-2.1,2.2,6.2,6.2,0,0,0-.8,3.1,6.5,6.5,0,0,0,.8,3.1,5.3,5.3,0,0,0,2.1,2.1,6,6,0,0,0,6,0Z"/>
    <path d="M334.5,80.2a4.8,4.8,0,0,1,.9,2.9,4.2,4.2,0,0,1-1.9,3.8,9.7,9.7,0,0,1-5.6,1.3h-9.7V69.5h9.2a9,9,0,0,1,5.3,1.3,4.3,4.3,0,0,1,1.8,3.6,4,4,0,0,1-.7,2.4,4.2,4.2,0,0,1-1.8,1.7A4.5,4.5,0,0,1,334.5,80.2Zm-11.9-7.4v4.4h4.3a4,4,0,0,0,2.4-.6,1.8,1.8,0,0,0,.8-1.7,1.7,1.7,0,0,0-.8-1.6,4.1,4.1,0,0,0-2.4-.5Zm7.6,11.6a1.9,1.9,0,0,0,.9-1.7c0-1.6-1.2-2.4-3.5-2.4h-5V85h5A4.5,4.5,0,0,0,330.2,84.4Z"/>
    <path d="M338.7,69.5h4.4V88.2h-4.4Z"/>
    <path d="M359.7,80.5h-4.6v4.6h-3.5V80.5H347V77.2h4.6V72.7h3.5v4.5h4.6Z"/>
  </svg>
);

export default ({ isLanding, homeLink }) => (  
  <GatsbyLink to={homeLink} aria-label='Forside'>
    <div className={clsx(
        styles.logoContainer, 
        isLanding ? styles.landingLogo : '',
        isLanding==='drive' ? styles.landingDrive : '',
        isLanding==='fleet' ? styles.landingFleet : '',
        isLanding==='vc' ? styles.landingVc : '',
      )}>
      {isLanding === 'drive'
        ? <Drive/>
        : isLanding === 'fleet'
          ? <Fleet/>
          : isLanding === 'vc'
            ? <VC/>
            : <Obi/>
      }
    </div>
  </GatsbyLink>
)