import React from 'react';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import { Link as GatsbyLink } from 'gatsby';
import Inner from './Inner';
import Dropdown from './Dropdown';
import MegaMenu from './Megamenu';
import styles from './styles.module.sass';

export default ({
  url,
  className,
  text,
  drop,
  megaMenu,
  inSub = false,
  selected = false,
  callToAction = false,
  isLanding,
  Wrapper = ({ children, className }) => drop || megaMenu ? (
      <div className={className}>
        {children}
      </div>
    ) : (
      <MenuItem className={className}
        disabled={selected}>
        {children}
      </MenuItem>
    ),
  LinkWrapper = ({ children }) => url ? (
      <GatsbyLink to={url} className={styles.anchorContainer}>
        {children}
      </GatsbyLink>
    ) : (
      <li className={styles.anchorContainer}>
        {children}
      </li>
    )
}) => (
  // In the keys.js file you can set urls to 'disabled' in order to disable specific menu item in specific language
  url !== 'disabled' ? <Wrapper className={clsx(
      styles.menuItem,
      inSub ? styles.subMenuItem : '',
      url ? styles.menuItemLink : styles.menuItemExpandable,
      callToAction ? styles.menuItemCtoa : '',
      className,
      callToAction && isLanding === 'drive' ? styles.driveCtoa : '',
      callToAction && isLanding === 'fleet' ? styles.fleetCtoa : '',
    )}
    disabled={selected}>
    
    {drop ? <Dropdown items={drop}/> : null}
    {megaMenu ? <MegaMenu menu={megaMenu}/> : null}
    <LinkWrapper>
      {selected && url
      ? <div className={clsx(
          styles.underline,
          callToAction ? styles.underlineCallToAction : ''
        )}/>
      : null}
      <Inner text={text}
        selected={selected}
        callToAction={callToAction}/>
    </LinkWrapper>
  </Wrapper>
  : null
);