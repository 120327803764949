import React from 'react';
import clsx from 'clsx';
import styles from './styles.module.sass';

class Overlay extends React.Component {
  constructor() {
    super();
    this.state = {
      containerStyle: styles.container
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({
      containerStyle: clsx(styles.container, styles.containerFaded)
    },  () => {
      setTimeout(() => {
        this.setState({
          containerStyle: clsx(styles.container, styles.containerFaded, styles.containerNone)
        });
      }, 500);
    }), 500);
  }

  render() {
    return (
      <div className={this.state.containerStyle}/>
    );
  }
}

export default Overlay;