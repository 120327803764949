export default {
  // Text
  // -- General
  'Language' : 'Language',
  'Read more' : 'Read more',
  'Try for free' : 'Try for free',
  'YES' : 'YES',
  'NO' : 'NO',
  'Next' : 'Next',
  'Name' : 'Name',
  'No garage' : 'No garage',
  'Add' : 'Add',
  'Whoops! Something went wrong. Please try again...' : 'Whoops! Something went wrong. Please try again...',

  // -- -- Industries
  // 'Fleet management' : 'Fleet management',
  'Drivers' : 'Drivers',
  'Driver' : 'Driver',
  'Leasing' : 'Leasing',
  'Repair shop' : 'Repair shop',
  'Dealership' : 'Dealership',
  'Repair shops' : 'Repair shops',
  'Dealerships' : 'Dealerships',

  // -- -- Header
  'Main' : 'Home',
  'Platform' : 'Platform',
  'Services' : 'Services',
  'Industries' : 'Customers',
  'Fleet' : 'Fleet',
  'Features' : 'Features',
  'Pricing' : 'Pricing',
  'About us' : 'About us',
  'Sign up' : 'Sign up',
  'Why Fleet' : 'Why Fleet',

  // -- -- -- Header Submenus
 
  // -- -- -- -- Platform menu

  'Platform overview' : 'Platform overview', 

  'Connecting to millions of vehicles' : 'Connecting to millions of vehicles',
  'Seamless backend' : 'Seamless backend',
  'Designed to scale and integrate' : 'Designed to scale and integrate',
  'Ready to use services' : 'Ready to use services',
  'From technology to customer adoption' : 'From technology to customer adoption',

  'Automating key processes' : 'Automating key processes',

  'Simplified data management' : 'Simplified data management',
  'Flexibility to adapt any setup' : 'Flexibility to adapt any setup',
  'Report, analyse and improve' : 'Report, analyse and improve',
  'Automating onboarding flows' : 'Automating onboarding flows',
  'Track usage and manage billing' : 'Track usage and manage billing',
  'Reducing administration tasks' : 'Reducing administration tasks',
  'Flexible administration' : 'Flexible administration',
  'Control over support operations' : 'Control over support operations',

  // -- -- -- -- Services menu

  'Vehicle care' : 'Vehicle care',
  'Digital link to customers and vehicles' : 'Digital link to customers and vehicles',
  'Modern fleet management' : 'Modern fleet management',
  'Drive' : 'Drive',
  'Car owner\'s best friend' : 'Car owner\'s best friend',


  // -- -- -- -- Customers menu

  'By profile' : 'By profile',
  'Learn more about our services' : 'Learn more about our services',

  'Your car\'s best friend' : 'Your car\'s best friend',
  'Fleet manager' : 'Fleet manager',
  'Track, maintain and manage vehicles' : 'Track, maintain and manage vehicles',
  'Increase customer retention' : 'Increase customer retention',
  'Increase the after sales value' : 'Increase the after sales value',

  'By use case' : 'By use case',
  'Exploring new exciting spaces' : 'Exploring new exciting spaces',

  'Remote vehicle monitoring' : 'Remote vehicle monitoring',
  'More efficient daily operations' : 'More efficient daily operations',
  'Preventive maintenance' : 'Preventive maintenance',
  'Schedule and automate maintenance' : 'Schedule and automate maintenance',
  'Green driving' : 'Green driving',
  'Identify areas to improve' : 'Identify areas to improve',
  'Digital communication' : 'Digital communication',
  'Use video or chat to communicate' : 'Use video or chat to communicate',
  'Customer satisfaction' : 'Customer satisfaction',
  'New tools to keep the customer satisfied' : 'New tools to keep the customer satisfied',
  'Digital documentation' : 'Digital documentation',
  'Store and view vehicle\'s history digitally' : 'Store and view vehicle\'s history digitally',
  'EV ownership' : 'EV ownership',
  'Adapting to the specific needs' : 'Adapting to the specific needs',


  // -- -- -- -- Fleet -> Features sub menu

  'Complete solution' : 'Complete solution',
  'Easy and simple to use' : 'Easy and simple to use',

  'Different vehicle types' : 'Different vehicle types',
  'Connect your car, van or trailer' : 'Connect your car, van or trailer',
  'Fleet dashboard' : 'Fleet dashboard',
  'For the administrator' : 'For the administrator',
  'App for drivers' : 'App for drivers',
  'For the employee on the road' : 'For the employee on the road',


  // -- -- -- -- Fleet -> Why Fleet sub menu

  'Case studies' : 'Case studies',

  'Construction' : 'Construction',
  'Downtown ApS' : 'Downtown ApS',
  'Education' : 'Education',
  'Zealand Erhvervsakademi' : 'Zealand Erhvervsakademi',

  'Benefits' : 'Benefits',
  'Exploring the benefits' : 'Exploring the benefits',

  'Reporting' : 'Reporting',
  'Schedule relevant reports' : 'Schedule relevant reports',
  'Preventive maintenace' : 'Preventive maintenace',
  'Schedule and automate all maintenance' : 'Schedule and automate all maintenance',
  'Increased safety for employees' : 'Increased safety for employees',
  'Identify issues remotely' : 'Identify issues remotely',
  'Driving and visiting history' : 'Driving and visiting history',
  'View vehicle\'s driving history' : 'View vehicle\'s driving history',
  'Less administrative work' : 'Less administrative work',


  // -- -- Call to Action
  'Get started with OBI+ today' : 'Get started with OBI+ today',
  'Email address' : 'Email address',
  'Product tour' : 'Product tour',
  'Consultation' : 'Consultation',
  'Trial' : 'Trial',
  'Perfect! We will contact you soon.' : 'Perfect! We will contact you soon.',

  // -- -- Footer
  'The company' : 'The company',
  'Resources' : 'Resources',
  'Partners' : 'Partners',
  'Terms' : 'Terms',
  'Privacy' : 'Privacy',

  // -- -- 404: Not found
  'NOT FOUND' : 'NOT FOUND',
  'This page does not exist.' : 'This page does not exist',

  // -- Main

  // -- -- Hero
  'OBI+ connected car services' : 'OBI+ connected car services',
  'The platform that' : 'The platform that',
  'enables growth' : 'enables growth',
  'empowers innovation' : 'empowers innovation',
  'drives loyalty' : 'drives loyalty',
  'delivers insights' : 'delivers insights',
  'A safer, better smarter future' : 'A safer, better smarter future',
  'powered by data-driven services.' : 'powered by data-driven services.',
  'Get started' : 'Get started',

  // -- -- Why
  'All needed to launch and scale a connected car service.' : 'All needed to launch and scale a connected car service.',
  'Choose the service for your business' : 'Choose the service for your business',
  'Get access to your admin account' : 'Get access to your admin account',
  'Select the model that fits your customers' : 'Select the model that fits your customers',
  'Launch the service with support form OBI+' : 'Launch the service with support form OBI+',

  // -- -- Solution
  'End-to-end solution' : 'End-to-end solution',
  'Run your service the way you need it. From plug & play data sources to ready to use connected car services. At OBI+ we are committed to continuously adding capabilities to the OBI+ platform and connecting it to the leading technology of tomorrow.' : 'Run your service the way you need it. From plug & play data sources to ready to use connected car services. At OBI+ we are committed to continuously adding capabilities to the OBI+ platform and connecting it to the leading technology of tomorrow.',
  'Data sources' : 'Data sources',
  'Standardization' : 'Standardization',
  'Administration' : 'Administration',
  'Suite of services' : 'Suite of services',
  'Integration' : 'Integration',

  // -- -- Value
  'Create value for customers' : 'Create value for customers',
  'Expand your service portfolio quickly and cost-effectively. We are true believers in developing connected car services that are useful for everyone. Choose any of the ready-to-use services to get started right away. All services could work either as a standalone solution or be seamlessly integrated together or into external systems.' : 'Expand your service portfolio quickly and cost-effectively. We are true believers in developing connected car services that are useful for everyone. Choose any of the ready-to-use services to get started right away. All services could work either as a standalone solution or be seamlessly integrated together or into external systems.',

  // -- -- Vehicle Care
  'Helping professionals to create a link to vehicles and customers to reach a higher service standard' : 'Helping professionals to create a link to vehicles and customers to reach a higher service standard',

  // -- -- Fleet
  'Web-based fleet management service that makes it easy to track, maintain, and manage vehicles.' : 'Web-based fleet management service that makes it easy to track, maintain, and manage vehicles',

  // -- -- Driver App
  'Enabling seamless digital interactions for car owners through connected car technology and multi-feature mobile applications' : 'Enabling seamless digital interactions for car owners through connected car technology and multi-feature mobile applications',

  // -- -- Industries
  'Customers across multiple industries' : 'Customers across multiple industries',
  'OBI+ helps all businesses ready to adopt connected car technology and services, regardless of size. Whether you are searching for innovative ways to drive customer retention and loyalty, optimize your business, or make vehicles more attractive, you get the tools to work for you.' : 'OBI+ helps all businesses ready to adopt connected car technology and services, regardless of size. Whether you are searching for innovative ways to drive customer retention and loyalty, optimize your business, or make vehicles more attractive, you get the tools to work for you.',

  // -- -- Advantages
  'What are your advantages' : 'What are your advantages',
  'Interoperability' : 'Interoperability',
  'All applications work together seamlessly to provide a complete service offering.' : 'All applications work together seamlessly to provide a complete service offering.',
  'Fast time to market' : 'Fast time to market',
  'Off-the-shelf applications ready to be used with the possibility for white labeling if required.' : 'Off-the-shelf applications ready to be used with the possibility for white labeling if required.',
  'Easy deployment' : 'Easy deployment',
  'Seamless deployment and operation designed to facilitate the needs of every customer or partner.' : 'Seamless deployment and operation designed to facilitate the needs of every customer or partner.',
  'Leveraging existing IT' : 'Leveraging existing IT',
  'Elaborate APIs that make it easy to integrate data into existing systems.' : 'Elaborate APIs that make it easy to integrate data into existing systems.',
  'Get started playbook' : 'Get started playbook',
  'Cooperating together with customers and partners to ensure smooth adoption.' : 'Cooperating together with customers and partners to ensure smooth adoption.',
  'Specialized expertise' : 'Specialized expertise',
  'Years of experience with connected services leads to specific know-how on important topics.' : 'Years of experience with connected services leads to specific know-how on important topics.',

  // -- Platform

  // -- -- SaaS tools
  'SaaS tools' : 'SaaS tools',
  'In addition to the powerful platform, we are providing a set of software tools to support key processes for implementing, managing, and improving the operations of our partners.' : 'In addition to the powerful platform, we are providing a set of software tools to support key processes for implementing, managing, and improving the operations of our partners.',
  'Digital agreements' : 'Digital agreements',
  'Agreements engine adapting to the service and use case' : 'Agreements engine adapting to the service and use case',
  'Business intelligence' : 'Business intelligence',
  'A customisable BI setup to help keeping track and improving.' : 'A customisable BI setup to help keeping track and improving.',
  'Subscription management' : 'Subscription management',
  'Adjustable subscriptions to adapt to the selected model and ease billing.' : 'Adjustable subscriptions to adapt to the selected model and ease billing.',
  'User management' : 'User management',
  'Set user management on auto-pilot and interfere whenever required' : 'Set user management on auto-pilot and interfere whenever required',
  'In-product onboarding' : 'In-product onboarding',
  'Secure a great start for customers by utilising in-product onboarding flows' : 'Secure a great start for customers by utilising in-product onboarding flows',
  'Smart assistant' : 'Smart assistant',
  'Automation of administrative and reporting tasks to ease operations' : 'Automation of administrative and reporting tasks to ease operations',
  'Access management' : 'Access management',
  'Flexibility in administrating access grants to adapt to any structure.' : 'Flexibility in administrating access grants to adapt to any structure.',
  'Support overview' : 'Support overview',
  'Having control of support operations when actions are necessary' : 'Having control of support operations when actions are necessary',

  // -- -- Leverage links
  'Leverage links to data sources' : 'Leverage links to data sources',

  // -- -- Plug & Play
  'Plug & play hardware and software' : 'Plug & play hardware and software',
  'With more than 1+ billion older vehicles driving on the roads and the diversity of use cases, the need for additional hardware is present.' : 'With more than 1+ billion older vehicles driving on the roads and the diversity of use cases, the need for additional hardware is present.',
  'We have made the process of establishing the desired link to a vehicle truly plug & play.  This includes both the required hardware and software to get connected in the fastest way and the supporting processes to enhance the data quality.' : 'We have made the process of establishing the desired link to a vehicle truly plug & play.  This includes both the required hardware and software to get connected in the fastest way and the supporting processes to enhance the data quality.',

  // -- -- Direct Link
  'Direct hardware-free link to major brands' : 'Direct hardware-free link to major brands',
  'Connecting to millions of vehicles through our partner network is already enabled for every single customer and partner eligible for it.' : 'Connecting to millions of vehicles through our partner network is already enabled for every single customer and partner eligible for it.',
  'The OBI+ platform is taking care of all steps required for you to gain access to the vehicles, thus making it easier than ever to be connected.' : 'The OBI+ platform is taking care of all steps required for you to gain access to the vehicles, thus making it easier than ever to be connected.',

  // -- -- Be Connected
  'Explore our services' : 'Explore our services',

  // -- -- Flowchart
  'Web-based platform to enable connected car services' : 'Web-based platform to enable connected car services',

  // -- Services

  // -- -- Hero
  'Explore the digital world powered by OBI+' : 'Explore the digital world powered by OBI+',

  // -- -- Overview
  'OBI+ Platform overview' : 'OBI+ Platform overview',
  'It is a powerful platform designed to enable connected car services at scale. All a business needs to expand its portfolio quickly and cost-effectively.' : 'It is a powerful platform designed to enable connected car services at scale. All a business needs to expand its portfolio quickly and cost-effectively.',
  
  // -- -- Be connected
  'Be connected tomorrow' : 'Be connected tomorrow',
  'Suite of applications used by you, your customers, partners, or service providers.' : 'Suite of applications used by you, your customers, partners, or service providers',

  // -- -- Vehicle Care
  'A web-based service for vehicle management' : 'A web-based service for vehicle management',
  // -- -- -- Features
  'Vehicle profile' : 'Vehicle profile',
  'Vehicle information available within a single page. Quick sub-menus to alerts, service and repair logbook, and communication with users.' : 'Vehicle information available within a single page. Quick sub-menus to alerts, service and repair logbook, and communication with users.',
  'Communication' : 'Communication',
  'Start a conversation with a single click. A convenient and easy way to assist customers who experience issues or want to schedule a time for a service.' : 'Start a conversation with a single click. A convenient and easy way to assist customers who experience issues or want to schedule a time for a service.',
  'Reports' : 'Reports',
  'Scheduled reports pointing one\'s attention to important events such as engine light on, upcoming service, and others.' : 'Scheduled reports pointing one\'s attention to important events such as engine light on, upcoming service, and others.',
  'Service and repair logbook' : 'Service and repair logbook',
  'Service overview' : 'Service overview',
  'Keep track of all performed and upcoming services. Attach related documentation in a digital format.' : 'Keep track of all performed and upcoming services. Attach related documentation in a digital format.',
  'Easy overview' : 'Easy overview',
  'All vehicles assigned to the account can be easily accessed. Quick navigation and filtering assist in the search process.' : 'All vehicles assigned to the account can be easily accessed. Quick navigation and filtering assist in the search process.',
  'Notifications' : 'Notifications',
  'Receive real-time notification for events that require your attention. Adjust the preferences to your individual needs and processes.' : 'Receive real-time notification for events that require your attention. Adjust the preferences to your individual needs and processes.',

  // -- -- Fleet
  'A fleet management service suitable for a variety of customer segments' : 'A fleet management service suitable for a variety of customer segments',
  // -- -- -- Features
  'Fleet management' : 'Fleet management',
  'Fleet management ' : 'Fleet management',
  'Fleet overview' : 'Fleet overview',
  'Easy and simple fleet monitoring that gives you an overview of your fleet.' : 'Easy and simple fleet monitoring that gives you an overview of your fleet.',
  'Automatic trip logging' : 'Automatic trip logging',
  'All journeys are automatically tracked. Relevant information about departure/arrival, addresses, duration, length, and type of trip are available, also in a historical view.' : 'All journeys are automatically tracked. Relevant information about departure/arrival, addresses, duration, length, and type of trip are available, also in a historical view.',
  'Fleet statistics' : 'Fleet statistics',
  'The latest statistics about your fleet are always available. Get valuable insights about your fleet such as utilization, fuel consumption, and more.' : 'The latest statistics about your fleet are always available. Get valuable insights about your fleet such as utilization, fuel consumption, and more.',
  'Easy communication' : 'Easy communication',
  'Through the in-built chat the user can be in contact with the service provider (e.g. mechanic) and stay always updated about important events that occur.' : 'Through the in-built chat the user can be in contact with the service provider (e.g. mechanic) and stay always updated about important events that occur.',
  'Personalized reports' : 'Personalized reports',
  'You can enable relevant reports such as notification about overspeeding, driving logs, geofencing, or usage of the vehicles after working hours.' : 'You can enable relevant reports such as notification about overspeeding, driving logs, geofencing, or usage of the vehicles after working hours.',
  'Geofencing' : 'Geofencing',
  'Create different geofences to be notified when vehicles enter or exit a certain area to keep track of the daily workflow or avoid unauthorized usage.' : 'Create different geofences to be notified when vehicles enter or exit a certain area to keep track of the daily workflow or avoid unauthorized usage.',

  // -- -- Driver App
  'A connected car service valuable for every driver with applications available on both iOS and Android devices' : 'A connected car service valuable for every driver with applications available on both iOS and Android devices',
  // -- -- -- Features
  'Vehicle overview' : 'Vehicle overview',
  'An easy overview for each driver. Car location and walking directions. Vehicle condition and information in case of alerts. Fuel level and mileage information.' : 'An easy overview for each driver. Car location and walking directions. Vehicle condition and information in case of alerts. Fuel level and mileage information.',
  // 'Automatic trip logging' : 'Automatic trip logging',
  // 'All journeys are automatically tracked. Relevant information about departure/arrival, addresses, duration, length, and type of trip are available, also in a historical view.' : 'All journeys are automatically tracked. Relevant information about departure/arrival, addresses, duration, length, and type of trip are available, also in a historical view.',
  'Trip categorization and quick notes' : 'Trip categorization and quick notes',
  'Select a default trip categorization or switch the purpose of a trip with a single tap. Add quick notes on a trip to easily remember which customer you visited or which event you attended.' : 'Select a default trip categorization or switch the purpose of a trip with a single tap. Add quick notes on a trip to easily remember which customer you visited or which event you attended.',
  'Driving logbook' : 'Driving logbook',
  'It gets easy once you have all trips automatically tracked. You can export a driving logbook report at any time with a single click.' : 'It gets easy once you have all trips automatically tracked. You can export a driving logbook report at any time with a single click.',
  // 'Communication' : 'Communication',
  'Through the in-built chat you can be in contact with your service provider (e.g. mechanic). In this way, you will be always updated about important events that occur.' : 'Through the in-built chat you can be in contact with your service provider (e.g. mechanic). In this way, you will be always updated about important events that occur.',
  'Choose between multiple vehicles' : 'Choose between multiple vehicles',
  'Have the flexibility to choose between one or more vehicles that you are driving. It makes it easier to keep track of vehicle utilization and management.' : 'Have the flexibility to choose between one or more vehicles that you are driving. It makes it easier to keep track of vehicle utilization and management.',

  // -- -- Industries
  'See how OBI+ services can empower your business' : 'See how OBI+ services can empower your business',

  // -- Fleet management

  // -- -- Main

  // -- -- -- Hero
  'Overview of your vehicles in the easiest way' : 'Overview of your vehicles in the easiest way',
  'Try it for free' : 'Try for free',
  'Hundreds of companies already use Fleet by OBI+' : 'Hundreds of companies already use Fleet by OBI+',

  // -- -- -- Why
  'Fleet is a simple to use fleet management solution for everyone within the company' : 'Fleet is a simple to use fleet management solution for everyone within the company',
  'Manage all vehicles' : 'Manage all vehicles',
  'Automate trip-logging' : 'Automate trip-logging',
  'Receive personalized reports' : 'Receive personalized reports',
  'Make repair and maintenance of cars easy' : 'Make repair and maintenance of cars easy',

  // -- -- -- Overview
  'Dashboard overview' : 'Dashboard overview',
  'Forget the boring paperwork and use smart notifications' : 'Forget the boring paperwork and use smart notifications',
  // -- -- -- -- Features
  'Automatic data entry and simple historical overview' : 'Automatic data entry and simple historical overview',
  'Relevant fleet statistics' : 'Relevant fleet statistics',
  'Receive automatic and personalized reports specific to your fleet usage' : 'Receive automatic and personalized reports specific to your fleet usage',

  // -- -- -- Mobile App
  'Show your employees trust while you keep the control' : 'Show your employees trust while you keep the control',
  'Mobile app for every driver' : 'Mobile app for every driver',
  // -- -- -- -- Features
  'Download a mobile application to view journeys and add notes' : 'Download a mobile application to view journeys and add notes',
  'Automatic trip logging and categorization' : 'Automatic trip logging and categorization',
  'Receive key vehicle notifications and communicate with your colleagues' : 'Receive key vehicle notifications and communicate with your colleagues',

  // -- -- -- Savings
  'Save your time and avoid unpleasant situations' : 'Save your time and avoid unpleasant situations',
  'See how the company vehicles are being used' : 'See how the company vehicles are being used',
  // -- -- -- -- Features
  'Show customers that a job has been done to avoid discussions' : 'Show customers that a job has been done to avoid discussions',
  'Communicate with employees while in the field' : 'Communicate with employees while in the field',
  'Define your own tags that pass the company\'s internal communication' : 'Define your own tags that pass the company\'s internal communication',
  
  // -- -- -- -- UsedBy
  'Customers across all industries' : 'Customers across all industries',
  'We help all businesses, regardless of size, to track their vehicles, reduce costs, increase productivity, increase safety, coordinate better repair and maintenance, and more.' : 'We help all businesses, regardless of size, to track their vehicles, reduce costs, increase productivity, increase safety, coordinate better repair and maintenance, and more.',
  // -- -- -- -- -- Icons
  'Craftsmen' : 'Craftsmen',
  'Leasing & rental' : 'Leasing & rental',
  'Wholesalers & retailers' : 'Wholesalers & retailers',
  'Plumbing' : 'Plumbing',
  'Transport & logistics' : 'Transport & logistics',
  'State & municipality' : 'State & municipality',
  'Agriculture' : 'Agriculture',
  'Sales & services' : 'Sales & services',
  
  // -- -- Features
  
  // -- -- -- Hero
  'A complete fleet management solution' : 'A complete fleet management solution',
  'Fleet is a simple to use fleet management solution for everyone within the company. Have a full overview of your fleet of vehicles, forget about paperwork and let your employees be more efficient.' : 'Fleet is a simple to use fleet management solution for everyone within the company. Have a full overview of your fleet of vehicles, forget about paperwork and let your employees be more efficient.',

  // -- -- -- Vehicles
  'Connect any vehicle type with plug&play adapter or hardware-free' : 'Connect any vehicle type with plug&play adapter or hardware-free',
  'Connect your car, van, trailer or other vehicles to the Fleet service. We have made it easy to get started and take advantage of all relevant features.' : 'Connect your car, van, trailer or other vehicles to the Fleet service. We have made it easy to get started and take advantage of all relevant features.',

  // -- -- -- Fleet dashboard -- -- see Text/Services/Fleet management

  'Dashboard ' : 'Dashboard',
  'Fleet web dashboard' : 'Fleet web dashboard',

  // -- -- -- Driver App -- -- see Text/Services/Driver App

  // -- -- Why Fleet?

  // -- -- -- Hero
  'One solution to simplify the vehicle related tasks' : 'One solution to simplify the vehicle related tasks',
  'Save time. Improve efficiency. Easy to use.' : 'Save time. Improve efficiency. Easy to use.',

  // -- -- -- FORDELE
  'Advantages' : 'Advantages',
  'Optimize your fleet' : 'Optimize your fleet',
  'No matter the size of your fleet, you can use information that you never had before. It is time to utilize data that matters to increase cost savings and driver retention rates.' : 'No matter the size of your fleet, you can use information that you never had before. It is time to utilize data that matters to increase cost savings and driver retention rates.',
  'Increase productivity' : 'Increase productivity',
  'Know where your vehicles are, whether they are moving or parked. Send over the closest driver to perform a job in the area. Access historical data about the utilization of the fleet and individual vehicles.' : 'Know where your vehicles are, whether they are moving or parked. Send over the closest driver to perform a job in the area. Access historical data about the utilization of the fleet and individual vehicles.',
  'Receive more professional service' : 'Receive more professional service',
  'Better management of your assets through remote supervision and coordination of key events. 8 out of 10 employees forget to notify a manager about key car-related events (e.g. service/engine light on).' : 'Better management of your assets through remote supervision and coordination of key events. 8 out of 10 employees forget to notify a manager about key car-related events (e.g. service/engine light on).',
  'Increase the safety of your drivers' : 'Increase the safety of your drivers',
  'Get peace of mind by knowing that your drivers and vehicles are safe to drive. Have a guardian angel (your mechanic) that can translate any issues that occur in simple words' : 'Get peace of mind by knowing that your drivers and vehicles are safe to drive. Have a guardian angel (your mechanic) that can translate any issues that occur in simple words.',
  'Prevent unauthorized mobility' : 'Prevent unauthorized mobility',
  'The service is definitely something for you if your goal is to reduce insurance and maintenance costs or to receive timely alerts and information about unauthorized usage of the vehicles.' : 'The service is definitely something for you If your goal is to reduce insurance and maintenance costs or to receive timely alerts and information about unauthorized usage of the vehicles.',
  'Automate data entry' : 'Automate data entry',
  'Eliminate manual mileage tracking to save time and increase the productivity of your drivers. Each driver or fleet manager can categorize the trips and add relevant notes.' : 'Eliminate manual mileage tracking to save time and increase the productivity of your drivers. Each driver or fleet manager can categorize the trips and add relevant notes.',

  // -- -- -- Motivation
  'Make repair and maintenance easy' : 'Make repair and maintenance easy',
  'Why complicate the administration of your vehicles when the professionals can help you in every step of the way?' : 'Why complicate the administration of your vehicles when the professionals can help you in every step of the way?',
  'Be contacted when key events like maintenances, inspections (syn), change of tires and others should be performed.' : 'Be contacted when key events like maintenances, inspections (syn), change of tires and others should be performed.',
  'Maintenance' : 'Maintenance',
  'Inspections' : 'Inspections',
  'Tire changes' : 'Tire changes',
  'Be contacted when key events should be performed.' : 'Be contacted when key events should be performed.',
  'Your employees should not be held accountable for forgetting to share that the engine lamp is on.' : 'Your employees should not be held accountable for forgetting to share that the engine lamp is on.',
  // -- -- -- -- Tiles
  'Safer vehicles to drive' : 'Safer vehicles to drive',
  'Communicate with your preferred garage at the right time.' : 'Communicate with your preferred garage at the right time.',
  'Receive help in breakdowns' : 'Receive help in breakdowns',

  // -- -- Pricing
  'What do you want to connect to?': 'What do you want to connect to?',
  'Cars' : 'Cars',
  'Vans' : 'Vans',
  'Trucks' : 'Trucks',
  'Trailers' : 'Trailers',

  'How many vehicles do you want to track?' : 'How many vehicles do you want to track?',
  'Number of vehicles' : 'Number of vehicles',

  'Do you want to select a preferred garage?' : 'Do you want to select a preferred garage?',
  'No, I do not want to select a garage.' : 'No, I do not want to select a garage',
  'Yes, I do have a preferred garage' : 'Yes, I do have a preferred garage',

  'One of the unique aspects of our service is the possibility to select a preferred garage to make vehicle management easier for you.' : 'One of the unique aspects of our service is the possibility to select a preferred garage to make vehicle management easier for you.',
  'Write the name of the garage or select it from the list' : 'Write the name of the garage or select it from the list',

  'Perfect. We are ready to send you a personal offer.' : 'Perfect. We are ready to send you a personal offer.',

  'Which email should we send the price request to?' : 'Which email should we send the price request to?',
  'Email' : 'Email',
  'Telephone number (optional)' : 'Telephone number (optional)',
  'Send me the offer' : 'Send me the offer',
  'Thank you!' : 'Thank you!',

  'By clicking \'Send me an offer\', you consent to OBI Plus ApS storing and processing the personal information you have provided above to deliver what you have requested.' : 'By clicking \'Send me an offer\', you consent to OBI Plus ApS storing and processing the personal information you have provided above to deliver what you have requested.',
  'You can unsubscribe from these messages at any time. For more information on how to unsubscribe, about our practices, and how we are committed to protecting and respecting your privacy, read our' : 'You can unsubscribe from these messages at any time. For more information on how to unsubscribe, about our practices, and how we are committed to protecting and respecting your privacy, read our',
  'Terms of Use and Privacy Policy' : 'Terms of Use and Privacy Policy',

  'Our sales team is currently preparing the offer for you.' : 'Our sales team is currently preparing the offer for you.',
  'While we finish, you can' : 'While we finish, you can',
  'Try Fleet for free' : 'Try Fleet for free',
  'Book a demo' : 'Book a demo',
  'Contact us' : 'Contact us',

  // -- Drivers

  // -- -- Hero
  'Your car\'s best friend.' : 'Your car\'s best friend.',
  'Safer driving. Convenient experience.' : 'Safer driving. Convenient experience.',

  // -- -- Pitching section
  'Connected car' : 'Connected car',
  'Innovative technology delivered in a simple and affordable way.' : 'Innovative technology delivered in a simple and affordable way.',
  'Car app' : 'Car app',
  'A better way to keep track of your car.' : 'A better way to keep track of your car.',
  'Smart link' : 'Smart link',
  'Peace of mind and minimal effort on car-related tasks' : 'Peace of mind and minimal effort on car-related tasks',

  // -- -- Overview
  'Easy car overview' : 'Easy car overview',
  'All car information in your hands. Follow parking, fuel level, and history of service and repairs.' : 'All car information in your hands. Follow parking, fuel level, and history of service and repairs.',
  'Peace of mind' : 'Peace of mind',
  'Have a safer and more reliable car. Follow changes in your car\'s condition and be advised by your preferred workshop.' : 'Have a safer and more reliable car. Follow changes in your car\'s condition and be advised by your preferred workshop.',
  'Stop wasting time on keeping track of your trips. All are tracked automatically and all relevant information is available.' : 'Stop wasting time on keeping track of your trips. All are tracked automatically and all relevant information is available.',
  'Hassle-free service and repair experience' : 'Hassle-free service and repair experience',
  'Convenient way to communicate with your workshop anytime you want. Always updated about important events that occur.' : 'Convenient way to communicate with your workshop anytime you want. Always updated about important events that occur.',

  // -- -- Advantages
  'How it helps you' : 'How it helps you',
  'Safer and reliable car' : 'Safer and reliable car',
  'No worries in a critical situation' : 'No worries in a critical situation',
  'Avoid expensive repairs' : 'Avoid expensive repairs',
  'Minimal efforts on car tasks' : 'Minimal efforts on car tasks',
  'Online dominated experience' : 'Online dominated experience',
  'Save time' : 'Save time',

  // -- -- Pricing
  'The price?' : 'The price?',
  'Less than you pay for a cup of coffee.' : 'Less than you pay for a cup of coffee.',
  'kr/m' : 'kr./m',

  // -- -- Compare
  'How is our service different?' : 'How is our service different?',
  'Compare' : 'Compare',
  'Service & repair experience' : 'Service & repair experience',
  'The old way' : 'The old way',
  'Service light or engine light on.' : 'Service light or engine light on.',
  'The car tells me to do something. I\'ll do it later.' : 'The car tells me to do something. I\'ll do it later.',
  '30 day later. There is something to do about my car.' : '30 day later. There is something to do about my car.',
  'Time sure flies. Should have left a note.' : 'Time sure flies. Should have left a note.',
  'I should remember to call the workshop' : 'I should remember to call the workshop',
  'Something should be done.' : 'Something should be done.',
  'Checking workshop\'s available hours. They work until 16:30 ... it\'s 17:00 now.' : 'Checking workshop\'s available hours. They work until 16:30 ... it\'s 17:00 now.',
  'Finally something is to be done.' : 'Finally something is to be done.',
  '3 days later. I need to call or visit. Might need to take time off from work.' : '3 days later. I need to call or visit. Might need to take time off from work.',
  'Too much work... wondering why it takes so long.' : 'Too much work... wondering why it takes so long.',
  '33+ days later. Car is finally at the shop.' : '33+ days later. Car is finally at the shop.',
  'We hope that you didn\'t have your engine light on for this period of time... its either unsafe or might get expensive.' : 'We hope that you didn\'t have your engine light on for this period of time... its either unsafe or might get expensive.',

  'The new way' : 'The new way',
  'The car tells me to do something. My phone will handle it or remind me later.' : 'The car tells me to do something. My phone will handle it or remind me later.',
  'Workshop is notified right away' : 'Your workshop is notified right away',
  'Something is being done already.' : 'Something should be done.',
  'I don\'t need to worry' : 'You don\'t worry',
  'You are contacted by your workshop with information about it and agree on a convenient time for you to visit.' : 'You are contacted by your workshop with information about it and agree on a convenient time for you to visit.',
  'All is settled in 1 day. Peace of mind.' : 'All is settled in 1 day. Peace of mind.',
  'Avoid making the issue more severe with time' : 'Avoid making the issue more severe with time',
  'Minimal efforts and hassle-free experience' : 'Minimal efforts and hassle-free experience',
  'You know if it\'s safe to drive meanwhile' : 'You know if it\'s safe to drive meanwhile',

  'Logbook services' : 'Logbook services',
  'Logbook service' : 'Logbook service',
  'Trip data collection' : 'Trip data collection',
  'Trip registration' : 'Trip registration',
  'Export reports' : 'Export reports',
  'Hardware' : 'Hardware',
  'Commitment' : 'Commitment',
  'Safety through connected car technology' : 'Safety through connected car technology',
  'Service & repair link' : 'Service & repair link',
  'Added value' : 'Added value',
  'Find my car' : 'Find my car',
  'Stats' : 'Stats',
  'Tips' : 'Tips',
  'Price' : 'Price',
  'Automated' : 'Automated',
  'Always unlimited' : 'Always unlimited',
  'No need or free' : 'No need or free',
  'No binding, same price' : 'No binding, same price',
  'Mobile app logbook' : 'Mobile app logbook',
  'Manual' : 'Manual',
  'Limited/unlimited plans' : 'Limited/unlimited plans',
  'Yes or higher price for freedom' : 'Yes or higher price for freedom',
  'Extra hardware logbook' : 'Extra hardware logbook',

  // -- -- Getting started

  'Getting started' : 'Getting started',
  'Step' : 'Step',
  'Sign up in 1 minute' : 'Sign up in 1 minute',
  'All you need is your car\'s license plate.' : 'All you need is your car\'s license plate.',
  'Complete the registration' : 'Complete the registration',
  'You will receive a confirmation email to complete your registration.' : 'You will receive a confirmation email to complete your registration.',
  'Connect and drive' : 'Connect and drive',
  'Connect your car at your preferred workshop or by yourself. Download the app.' : 'Connect your car at your preferred workshop or by yourself. Download the app.',

  // -- -- FAQ
  'Get more answers.' : 'Get more answers.',

  // -- -- Pricing  
  'What is the number plate of your car?' : 'What\'s your car\'s number plate?',
  'Which garage do you want to select as a preferred workshop?' : 'Which garage do you want to select as a preferred workshop?',
  'Select the garage of your choice. We will inform them about your choice and help you coordinate how to connect your car.' : 'Select the garage of your choice. We will inform them about your choice and help you coordinate how to connect your car.',
  'Ready to get started?' : 'Ready to get started?',
  'We will send you a confirmation email once we process your sign up form.' : 'We will send you a confirmation email once we process your sign up form.',

  // -- Repair shop

  // -- -- Main

  // -- -- -- Hero
  'Create a link to vehicles and customers' : 'Create a link to vehicles and customers',
  'Stay informed about the condition of each connected vehicle and be closer to every customer.' : 'Stay informed about the condition of each connected vehicle and be closer to every customer.',
  'Take a quick tour' : 'Take a quick tour',

  // -- -- -- Contact Customers
  'Contact customers at the right time' : 'Contact customers at the right time',
  'Know that a problem has occurred before a customer calls you and take a more informed decision when advising a customer.' : 'Know that a problem has occurred before a customer calls you and take a more informed decision when advising a customer.',

  // -- -- -- Peace of mind
  'Peace of mind for each customer' : 'Peace of mind for each customer',
  'The customers benefit from easy to use services that make their life easier and safer. We have solutions for both business and private customers that present a new convenient way to be connected to everyone.' : 'The customers benefit from easy to use services that make their life easier and safer. We have solutions for both business and private customers that present a new convenient way to be connected to everyone.',

  // FIXME: Keys will not be used in the Perks section

  // -- -- -- Clients
  'Hundreds of workshops already use Vehicle Care' : 'Hundreds of workshops already use Vehicle Care',

  // -- -- Features

  // -- -- -- Hero  
  'A workshop management solution powered by data' : 'A workshop management solution powered by data',

  // -- -- -- Pitch
  'Vehicle Care for you' : 'Vehicle Care for you',
  'A workshop service for mechanics that links them to vehicles' : 'A workshop service for mechanics that links them to vehicles',

  // -- -- -- Features
  'Direct link to vehicles 24/7' : 'Direct link to vehicles 24/7',
  'Have remote access to relevant vehicle data like never before. It is fast, simple, and creates value for your customers.' : 'Have remote access to relevant vehicle data like never before. It is fast, simple, and creates value for your customers.',
  'Important warnings' : 'Important warnings',
  'Be proactive in helping customers when an undesired situation occurs. Be notified of important warnings such as engine light on, battery low, and others.' : 'Be proactive in helping customers when an undesired situation occurs. Be notified of important warnings such as engine light on, battery low, and others.',
  // 'Service and repair logbook' : 'Service and repair logbook',
  'Track, schedule and be notified when a vehicle is due for service or inspection. Have it always as a backup accessible also by vehicle owners.' : 'Track, schedule and be notified when a vehicle is due for service or inspection. Have it always as a backup accessible also by vehicle owners.',
  'Up-to-date maintenance' : 'Up-to-date maintenance',
  'Make sure that all vehicles are well-maintained and safe to drive. Newer vehicles are being serviced properly and within service intervals to avoid issues with warranty.' : 'Make sure that all vehicles are well-maintained and safe to drive. Newer vehicles are being serviced properly and within service intervals to avoid issues with warranty.',
  // 'Reports' : 'Reports',
  'Scheduled reports pointing one\'s attention to important events such as engine light on, upcoming service, and others' : 'Scheduled reports pointing one\'s attention to important events such as engine light on, upcoming service, and others.',

  // -- -- -- Services
  'Services for your customers' : 'Services for your customers',
  'Bring customers closer to your workshop by giving them a useful tool in their daily life.' : 'Bring customers closer to your workshop by giving them a useful tool in their daily life.',

  // -- -- -- -- Fleet
  'An easy to use fleet management dashboard for any fleet size' : 'An easy to use fleet management dashboard for any fleet size',
  'Automatic trip-logging and a simple historical overview' : 'Automatic trip-logging and a simple historical overview',
  'Personalized reports specific to their unique fleet usage' : 'Personalized reports specific to their unique fleet usage',

  // -- -- -- -- Driver App
  'A mobile app for every driver that gives them a good overview' : 'A mobile app for every driver that gives them a good overview',
  'Convenient contact with their preferred workshop' : 'Convenient contact with their preferred workshop',
  'Automatic logging and categorization of trips' : 'Automatic logging and categorization of trips',

  // -- -- Pricing
  'Which customer types do you want to connect to your repair shop?' : 'Which customer types do you want to connect to your repair shop?',
  'Write down the email we should use to answer your request.' : 'Write down the email we should use to answer your request.',
  'Business' : 'Business',
  'Private' : 'Private',
  'Empower your repair shop today.' : 'Empower your repair shop today.',

  'Perfect' : 'Perfect',
  'Thank you for your interest. Write down the email we should use to answer your request.' : 'Thank you for your interest. Write down the email we should use to answer your request.',

  // -- Dealerships

  // -- -- Main

  // -- -- -- Hero
  'Connected car services for car dealerships' : 'Connected car services for car dealerships',
  'A better way to' : 'A better way to',
  'be proactive' : 'be proactive',
  'manage vehicles' : 'manage vehicles',
  'create value' : 'create value',

  // -- -- -- Digital Services
  'Digital services for dealers and customers' : 'Digital services for dealers and customers',
  'A complete solution for dealerships that want to be proactive in serving their customers by leveraging vehicle data.' : 'A complete solution for dealerships that want to be proactive in serving their customers by leveraging vehicle data.',

  // -- -- -- Data Driven Vehicles
  'Data-driven vehicle management' : 'Data-driven vehicle management',
  'Empowering the workshop department to increase customer retention and lifetime value of a customer. Know when customers need professional assistance.' : 'Empowering the workshop department to increase customer retention and lifetime value of a customer. Know when customers need professional assistance.',

  // -- -- -- Motivation
  'More value for everyone' : 'More value for everyone',
  // 'Safer vehicles to drive' : 'Safer vehicles to drive',
  'Convenient planning of new appointments' : 'Convenient planning of new appointments',
  'Acting on time to avoid critical issues' : 'Acting on time to avoid critical issues',

  // -- -- Features

  // -- -- -- Hero
  'A unique dealer management solution' : 'A unique dealer management solution',

  // -- -- -- Vehicle Care
  'Vehicle care for dealerships' : 'Vehicle Care for dealerships',
  'Service for remote monitoring of vehicle data and proactive customer management' : 'Service for remote monitoring of vehicle data and proactive customer management',
  // -- -- -- -- Features
  'Advanced remote data access' : 'Advanced remote data access',
  'Specific data coverage to the vehicles that your workshop is an expert on. Providing new insights that were not available before.' : 'Specific data coverage to the vehicles that your workshop is an expert on. Providing new insights that were not available before.',
  'Timely customer services' : 'Timely customer services',
  'Build a closer relationship with customers by having more possibilities to coordinate maintenance and repair.' : 'Build a closer relationship with customers by having more possibilities to coordinate maintenance and repair.',
  'Track agreements' : 'Track agreements',
  'A unique way to track service and leasing agreements making it easier to manage operations and communication with customers.' : 'A unique way to track service and leasing agreements making it easier to manage operations and communication with customers.',
  'Important notifications' : 'Important notifications',
  'Leverage the power of connected car data to be notified when important events occur. Increase productivity and optimize the business.' : 'Leverage the power of connected car data to be notified when important events occur. Increase productivity and optimize the business.',
  'Integrate to other systems' : 'Integrate to other systems',
  'Easy to implement integrations to other relevant systems used by your departments.' : 'Easy to implement integrations to other relevant systems used by your departments.',

  // -- -- -- Services
  // 'Services for your customers' : 'Services for your customers',
  'Expand your dealership portfolio by offering customers new digital services' : 'Expand your dealership portfolio by offering customers new digital services',

  // -- -- -- -- Fleet
  'Fleet by OBI+' : 'Fleet by OBI+',
  // 'An easy to use fleet management dashboard for any fleet size' : 'An easy to use fleet management dashboard for any fleet size',
  // 'Automatic trip-logging and a simple historical overview' : 'Automatic trip-logging and a simple historical overview',
  // 'Personalized reports specific to their unique fleet usage' : 'Personalized reports specific to their unique fleet usage',

  // -- -- -- -- Driver App
  'Driver App' : 'Driver App',
  // 'A mobile app for every driver that gives them a good overview' : 'A mobile app for every driver that gives them a good overview',
  // 'Convenient contact with their preferred workshop' : 'Convenient contact with their preferred workshop',
  // 'Automatic logging and categorization of trips' : 'Automatic logging and categorization of trips',

  // -- -- Pricing
  'Empower your dealership today.' : 'Empower your dealership today.',
  'Which customer types do you want to connect to your dealership?' : 'Which customer types do you want to connect to your dealership?',

  // -- Leasing

  // -- -- Main

  // -- -- -- Hero
  'Connected car services for leasing' : 'Connected car solution for leasing',
  'A smart solution to' : 'A smart solution to',
  // 'manage vehicles' : 'manage vehicles',
  'ease administration' : 'ease administration',
  'add value' : 'add value',

  // -- -- -- Simplify
  'Simplify vehicle management' : 'Simplify vehicle management',
  'Data-driven vehicle management solution for leasing companies. Leverage up-to-date insights into the condition of vehicles and the status of agreements.' : 'Data-driven vehicle management solution for leasing companies. Leverage up-to-date insights into the condition of vehicles and the status of agreements.',

  // -- -- -- Expand Portofolio
  'Expand the service portfolio' : 'Expand the service portfolio',
  'Provide customers with a complete offering by introducing new digital services that customers can benefit from when leasing vehicles.' : 'Provide customers with a complete offering by introducing new digital services that customers can benefit from when leasing vehicles.',

  // -- -- -- Motivation
  'Valuable for your customers' : 'Valuable for your customers',
  'Ease their responsibilities' : 'Ease their responsibilities',
  'Take advantage of new services' : 'Take advantage of new services',
  'Take action on time to avoid critical issues' : 'Take action on time to avoid critical issues',

  // -- -- Features

  // -- -- -- Hero
  'A solution that makes vehicle management easier for leasing companies' : 'A solution that makes vehicle management easier for leasing companies',

  // -- -- -- Vehicle Care
  'Vehicle care for leasing' : 'Vehicle Care for leasing',
  'Powered by relevant vehicle data to optimize business operations' : 'Powered by relevant vehicle data to optimize business operations',
  // -- -- -- -- Features
  'Relevant vehicle data' : 'Relevant vehicle data',
  'Access to data about mileage, service maintenance, vehicle status and other parameters.' : 'Access to data about mileage, service maintenance, vehicle status and other parameters.',
  'Timely reporting' : 'Timely reporting',
  'Be informed on time through tailored reports about improper usage like crossing country borders or driving with hazardous vehicle issues.' : 'Be informed on time through tailored reports about improper usage like crossing country borders or driving with hazardous vehicle issues.',
  'Manage agreements' : 'Manage agreements',
  'An automated tool to track leasing agreements making it easier to manage operations and communication with customers.' : 'An automated tool to track leasing agreements making it easier to manage operations and communication with customers.',
  // 'Communication' : 'Communication',
  'Improve customer service by communicating with customers through a new convenient channel.' : 'Improve customer service by communicating with customers through a new convenient channel.',
  'Integrate data to other systems' : 'Integrate data to other systems',
  'Optimize your leasing business by leveraging data and insights.' : 'Optimize your leasing business by leveraging data and insights.',

  // -- -- -- Services
  // 'Services for your customers' : 'Services for your customers',
  'Offering customers new digital services as added value' : 'Offering customers new digital services as added value',
  // -- -- -- -- Fleet
  // 'An easy to use fleet management dashboard for any fleet size' : 'An easy to use fleet management dashboard for any fleet size',
  // 'Automatic trip-logging and a simple historical overview' : 'Automatic trip-logging and a simple historical overview',
  // 'Personalized reports specific to their unique fleet usage' : 'Personalized reports specific to their unique fleet usage',
  // -- -- -- -- Driver App
  // 'A mobile app for every driver that gives them a good overview' : 'A mobile app for every driver that gives them a good overview',
  // 'Convenient contact with their preferred workshop' : 'Convenient contact with their preferred workshop',
  // 'Automatic logging and categorization of trips' : 'Automatic logging and categorization of trips',

  // -- -- Pricing
  'Empower your business today' : 'Empower your business today',
  'Which vehicle types do you want to connect to your leasing company?' : 'Which vehicle types do you want to connect to your leasing company?',
  'How many vehicles do you want connected?' : 'How many vehicles do you want connected?',

  // -- About Us

  // -- -- Top Section
  // Left
  'The platform enabling the fastest delivery of connected car services.' : 'The platform enabling the fastest delivery of connected car services.',
  // Right
  'Who are we?' : 'Who are we?',
  'OBI+ is a tech company offering a cloud-based platform that provides the infrastructure and digital products to make connected car services accessible to everyone.' : 'OBI+ is a tech company offering a cloud-based platform that provides the infrastructure and digital products to make connected car services accessible to everyone.',
  'At its core, OBI+ is driven by a great team of engineers, designers, marketeers with a mission to empower innovation through a suite of services built to enable personalized digital experiences for every car driver, fleet operator, mechanic, and any other service provider who recognizes the potential for a safer, better, smarter future powered by data.' : 'At its core, OBI+ is driven by a great team of engineers, designers, marketeers with a mission to empower innovation through a suite of services built to enable personalized digital experiences for every car driver, fleet operator, mechanic, and any other service provider who recognizes the potential for a safer, better, smarter future powered by data.',
  // -- -- Vision
  // Left
  'Our vision' : 'Our vision',
  'Our vision is to enable a safer, better, smarter future by making connected car services accessible for everyone.' : 'Our vision is to enable a safer, better, smarter future by making connected car services accessible for everyone.',
  'We aim to be the leading provider of connected car services. Thus, we will be progressively adding integrations to new smart platforms, applications, and other software services. Our commitment is to continuously add capabilities to the OBI+ platform over time and connect the leading technology of tomorrow.' : 'We aim to be the leading provider of connected car services. Thus, we will be progressively adding integrations to new smart platforms, applications, and other software services. Our commitment is to continuously add capabilities to the OBI+ platform over time and connect the leading technology of tomorrow.',
  // Right
  'For a safer, better, smarter future powered by data.' : 'For a safer, better, smarter future powered by data.',

  // -- -- Digital Word
  'The digital world powered by OBI+' : 'The digital world powered by OBI+',
  'Next generation of connected car services in one place. Welcome to a world of digital touchpoints that create value, deliver insights and enable growth.' : 'Next generation of connected car services in one place. Welcome to a world of digital touchpoints that create value, deliver insights and enable growth.',

  // -- -- Partners
  'Commercial partners' : 'Commercial partners',
  'We are here for those who refuse to settle, who believe in the future of connected mobility. Because today\'s world deserves a partner that is able to adapt fast, recognizes the needs of different types of vehicle users, and is ready to push into new exciting spaces.' : 'We are here for those who refuse to settle, who believe in the future of connected mobility. Because today\'s world deserves a partner that is able to adapt fast, recognizes the needs of different types of vehicle users, and is ready to push into new exciting spaces.',
  'Repair shop concepts' : 'Repair shop concepts',
  'Serviced fleet management' : 'Serviced fleet management',
  'Want to become a partner?' : 'Want to become a partner?',
  'Contact our business team' : 'Contact our business team',
  'In-vehicle data into complete software solutions' : 'In-vehicle data into complete software solutions',
  'Edge computing & off-the-shelf connected services' : 'Edge computing & off-the-shelf connected services',
  'Car data and connected car services as a complete package' : 'Car data and connected car services as a complete package',

  // -- -- Partners Use Cases
  'Let\'s work together to make your project a reality' : 'Let\'s work together to make your project a reality',
  'We are already supporting a variety of commercial use cases and we continue to search for new ideas and concepts that drive better digital experiences. Let us know what you want to do.' : 'We are already supporting a variety of commercial use cases and we continue to search for new ideas and concepts that drive better digital experiences. Let us know what you want to do.',
  'Remote vehicle management' : 'Remote vehicle management',
  'Usage-based services fueled by car data' : 'Usage-based services fueled by car data',
  'Innovative on-demand car-related services' : 'Innovative on-demand car-related services',
  'Unique driver assistant services' : 'Unique driver assistant services',
  'Smart location-based services' : 'Smart location-based services',

  // -- -- Contact Us
  'Write to' : 'Write to',
  'Call' : 'Call',
  'Address' : 'Address',
  'Join us' : 'Join us',
  'Blog' : 'Blog',

  // -- Try now
  'Perfect. Which email should we use to create your account?' : 'Perfect. Which email should we use to create your account?',
  'Perfect. Thank you for your interest.' : 'Perfect. Thank you for your interest.',
  'Send': 'Send',
  'By clicking \'Send\', you consent to OBI Plus ApS storing and processing the personal information you have provided above to deliver what you have requested.' : 'By clicking \'Send\', you consent to OBI Plus ApS storing and processing the personal information you have provided above to deliver what you have requested.',
  'Our sales team will follow up soon.' : 'Our sales team will follow up soon.',
  'As soon as we have received your information, we will create an account in OBI+ for you. You can get started right away! We will contact you soon to answer any questions and make sure that you have a great start.' : 'As soon as we have received your information, we will create an account in OBI+ for you. You can get started right away! We will contact you soon to answer any questions and make sure that you have a great start.',

  // -- Blog
  'OBI + is a Danish IT company that provides an application platform for connected car services.' : 'OBI + is a Danish IT company that provides an application platform for connected car services.',
  'Our services bridge the gap between what service providers do today and what vehicle users want. Here we share knowledge about the automotive industry, connected car technologies, fleet management, tips & use cases that you can take advantage of.' : 'Our services bridge the gap between what service providers do today and what vehicle users want. Here we share knowledge about the automotive industry, connected car technologies, fleet management, tips & use cases that you can take advantage of.',
  'Posts' : 'Posts',
  'of' : 'of',

  // -- Sign up
  'Choose which of the services you want to see in action' : 'Choose which of the services you want to see in action',
  'You can choose 1 or more of the services below.' : 'You can choose 1 or more of the services below.',

  'Web-based fleet management' : 'Web-based fleet management',
  'Service for every driver' : 'Service for every driver',
  'Administration fleet on one\'s fleet per day' : 'Administration fleet on one\'s fleet per day',

  // -- ROI calculators

  'It pays to use' : 'It pays to use',
  'our services' : 'our services',

  // -- -- Fleet
  'Number of employees/vehicles running': 'Number of employees/vehicles running',
  
  'Number of routes per day per employee/vehicle' : 'Number of routes per day per employee/vehicle',
  'Time consumption for driving registration per route' : 'Time consumption for driving registration per route',
  'Employee hourly wage' : 'Employee hourly wage',
  'Calculate' : 'Calculate',

  'Time consumption per day' : 'Time consumption per day',
  'Time consumption per month (20 days)' : 'Time consumption per month (20 days)',
  'Spending per month' : 'Spending per month',
  'Expenditure per year' : 'Expenditure per year',
  'Savings per year using our fleet service' : 'Savings per year using our fleet service',

  // -- Use cases
  'Empowering innovation and exploring new exciting spaces.' : 'Empowering innovation and exploring new exciting spaces.', 
  'Vehicle connectivity is soon to be a requirement for any modern fleet manager, repair shop, roadside assistance, and other service providers.' : 'Vehicle connectivity is soon to be a requirement for any modern fleet manager, repair shop, roadside assistance, and other service providers.',
  'The remote link to vehicles is a fast, simple and value-adding opportunity to create a better experience for drivers. In today’s online-dominated world, people are increasing expectations towards their service providers. Remove access to vehicle data will be the new normal to which everyone shall adapt.' : 'The remote link to vehicles is a fast, simple and value-adding opportunity to create a better experience for drivers. In today’s online-dominated world, people are increasing expectations towards their service providers. Remove access to vehicle data will be the new normal to which everyone shall adapt.',
  'Schedule and automate all regular maintenance tasks, no matter if it’s about own vehicles or customer vehicles.' : 'Schedule and automate all regular maintenance tasks, no matter if it’s about own vehicles or customer vehicles.',
  'By using the OBI+ services, fleet managers can increase efficiency and maximise vehicle update, workshops can be proactive and contact customers at the right time, while drivers should not feel helpless when the service light turns on. OBI+ adds recommended maintenance schedules directly to make daily life better and smarter.' : 'By using the OBI+ services, fleet managers can increase efficiency and maximise vehicle update, workshops can be proactive and contact customers at the right time, while drivers should not feel helpless when the service light turns on. OBI+ adds recommended maintenance schedules directly to make daily life better and smarter.',
  'Store and view any vehicle-related documents in one place. An organised order of events and information such as what, when and who performed service is an important part of the ownership.' : 'Store and view any vehicle-related documents in one place. An organised order of events and information such as what, when and who performed service is an important part of the ownership.',
  'Other documents like leasing or insurance agreements come handy when an unexpected situation occurs. Having information in a digital format helps to keep track and make informed decisions as well as sharing internally or with potential buyers.' : 'Other documents like leasing or insurance agreements come handy when an unexpected situation occurs. Having information in a digital format helps to keep track and make informed decisions as well as sharing internally or with potential buyers.',
  'Use video or chat to communicate better in a number of cases. For instance, when a vehicle needs repair, often the problem is unclear. In such cases the mechanic must investigate the issue and communicate with the owner.' : 'Use video or chat to communicate better in a number of cases. For instance, when a vehicle needs repair, often the problem is unclear. In such cases the mechanic must investigate the issue and communicate with the owner.',
  'Why not make it easier for the owner to understand by recording a video and sending it seamlessly? Or if as a driver, the issue appears while on the road. Why not having an expert assisting remotely? Access to options for digital communication should be simple and straightforward.' : 'Why not make it easier for the owner to understand by recording a video and sending it seamlessly? Or if as a driver, the issue appears while on the road. Why not having an expert assisting remotely? Access to options for digital communication should be simple and straightforward.',
  'More efficient daily operations for any business operating with vehicles, no matter the size. Fleet management is often mistaken with vehicle tracking. However, tracking is only one of the aspects that could improve operations.' : 'More efficient daily operations for any business operating with vehicles, no matter the size. Fleet management is often mistaken with vehicle tracking. However, tracking is only one of the aspects that could improve operations.',
  'Fleet management combines all tasks of a fleet owner/manager, from selecting the vehicles that fit the operations, operating these on a daily basis, to selling those at some point. Throughout all that time, there is a need to complete mandatory vehicle-related tasks like service and maintenance, fuel management, security, manage responsibilities of employees, tracking usage, and more. Managing the fleet operations should be easier and more convenient.' : 'Fleet management combines all tasks of a fleet owner/manager, from selecting the vehicles that fit the operations, operating these on a daily basis, to selling those at some point. Throughout all that time, there is a need to complete mandatory vehicle-related tasks like service and maintenance, fuel management, security, manage responsibilities of employees, tracking usage, and more. Managing the fleet operations should be easier and more convenient.',
  'Vehicle owners should be aware of ways that can help them to improve their driving. Through the use of data, the opportunity for greener driving is more accessible to everyone.' : 'Vehicle owners should be aware of ways that can help them to improve their driving. Through the use of data, the opportunity for greener driving is more accessible to everyone.',
  'From a driver perspective, there could be ways to reduce fuel consumption or improve driving habits by becoming aware of events as a part of the driving. From a fleet manager perspective, one can improve the overall fleet performance by following average fuel consumption, idling events and vehicle utilization to reduce CO2 emissions.' : 'From a driver perspective, there could be ways to reduce fuel consumption or improve driving habits by becoming aware of events as a part of the driving. From a fleet manager perspective, one can improve the overall fleet performance by following average fuel consumption, idling events and vehicle utilization to reduce CO2 emissions.',
  'The primary reasoning for having a car is to get from A to B. It cannot be expected that car owners are experts in how a vehicle functions or what it needs are.' : 'The primary reasoning for having a car is to get from A to B. It cannot be expected that car owners are experts in how a vehicle functions or what it needs are.',
  'For optimal satisfaction, owners want a safe and reliable vehicle, minimal efforts on car-related tasks, no worries in case of issues, and all that enforced by online-dominated experience. The service provider should be closer to this scenario in order to keep customers satisfied.' : 'For optimal satisfaction, owners want a safe and reliable vehicle, minimal efforts on car-related tasks, no worries in case of issues, and all that enforced by online-dominated experience. The service provider should be closer to this scenario in order to keep customers satisfied.',
  'Ownership of electric vehicles is new to almost everyone. It has its characteristics and specific needs.' : 'Ownership of electric vehicles is new to almost everyone. It has its characteristics and specific needs.',
  'The number of electric vehicles will grow tremendously in the next few years and with that the interest in digital services that make EV ownership more convenient for private owners, businesses or public institutions. Both the EV owners and their service providers would require a new set of tools to help them.' : 'The number of electric vehicles will grow tremendously in the next few years and with that the interest in digital services that make EV ownership more convenient for private owners, businesses or public institutions. Both the EV owners and their service providers would require a new set of tools to help them.',

  // -- Image ALT descriptions
  // -- -- Home
  'A suite of connected car services' : 'A suite of connected car services',
  'Mechanic from auto repair shop using connected car services' : 'Mechanic from auto repair shop using connected car services',
  'Vehicle Care connected car platform by Obiplus' : 'Vehicle Care connected car platform by Obiplus',
  'Fleet operator manager using web-based fleet management service' : 'Fleet operator manager using web-based fleet management service ',
  'Fleet management by Obiplus' : 'Fleet management by Obiplus',
  'Mobile application Drive by Obiplus' : 'Mobile application Drive by Obiplus',
  'Car owner using Drive app by Obiplus' : 'Car owner using Drive app by Obiplus',

  // -- -- Platform
  'Hardware-free link to major car brands' : 'Hardware-free link to major car brands',

  // -- -- Services
  'OBI+ provides digital services through connected car' : 'OBI+ provides digital services through connected car',

  // -- -- Customers -> Driver
  'Car application for every driver' : 'Car application for every driver',
  'Mobile app for every car driver' : 'Mobile app for every car driver',

  // -- -- Customers -> Fleet
  'Fleet management solution for everyone within the company' : 'Fleet management solution for everyone within the company',
  'Fleet management for any fleet size and vehicle' : 'Fleet management for any fleet size and vehicle',
  'Fleet management solution for your vehicles' : 'Fleet management solution for your vehicles',
  'Connect your car, van, or trailer' : 'Connect your car, van, or trailer',

  // -- -- Customers -> Repair shops (Vehicle care)
  'A workshop management solution powered by data.' : 'A workshop management solution powered by data.',

  // -- -- Customers -> Dealerships
  'A unique solution for dealerships' : 'A unique solution for dealerships',

  // -- -- Use cases
  'Use cases of how connected car solutions used' : 'Use cases of how connected car solutions used',
  'Remote vehicle monitoring through connected car services' : 'Remote vehicle monitoring through connected car services',
  'Preventive maintenance - shedule and automate maintenance' : 'Preventive maintenance - shedule and automate maintenance',
  'Store and view all vehicle-related documents' : 'Store and view all vehicle-related documents',
  'Digital communication through video or chat' : 'Digital communication through video or chat',
  'Manage all fleet-related operations on a connected car platform' : 'Manage all fleet-related operations on a connected car platform',
  'Reduce CO2 emissions for your fleet' : 'Reduce CO2 emissions for your fleet',
  'Minimal efforts on car-related tasks' : 'Minimal efforts on car-related tasks',
  'EV ownership and digital services for electric vehicles' : 'EV ownership and digital services for electric vehicles',


  // -- Footer
  'See our blog' : 'See our blog',
  'Telephone' : 'Telephone',
  'Contact' : 'Contact',
  'General inquiries' : 'General inquiries',
  'Monday to Friday 09:00 to 16:00' : 'Monday to Friday 09:00 to 16:00 (CET)',
  'Fleet managers' : 'Fleet managers',
  'The blog' : 'The blog',

  //Landing pages
  // -- Fleet -> Sign up
  'Fill out the form' : 'Fill out the form',
  'As soon as we have received your information, we will create an account in Fleet for you. You can get started right away.' : 'As soon as we have received your information, we will create an account in Fleet for you. You can get started right away.',
  'We will contact you soon to answer any questions and make sure that you have a great start.' : 'We will contact you soon to answer any questions and make sure that you have a great start.',
  'Try for free in 30 days' : 'Try for free in 30 days',
  'When you have submitted the form and we have received your information, you can test the service for free.' : 'When you have submitted the form and we have received your information, you can test the service for free.',
  'End of the test period' : 'End of the test period',
  'When your test period finishes, we will contact you to discuss how you want to use Fleet in the future.' : 'When your test period finishes, we will contact you to discuss how you want to use Fleet in the future.',

  // URLs
  '_url:_' : 'https://en.obiplus.com',
  '_url:main' : '/',
  '_url:platform' : '/connected-car-platform',
  '_url:services' : '/connected-car-services',
  '_url:about-us' : '/about-us',
  '_url:fleet-management' : '/fleet-management-service',
  '_url:fleet-management-features' : '/fleet-management-features',
  '_url:fleet-management-why' : '/fleet-management-benefits',
  '_url:fleet-management-pricing' : '/fleet-management-price',
  '_url:fleet-management-pricing-tables' : 'disabled', // Set to disabled so that it is not shown in the EN version. 
  '_url:drivers' : '/drivers',
  '_url:drivers-pricing' : '/sign-up-driver-app',
  '_url:repair-shop' : '/vehicle-care',
  '_url:repair-shop-features' : '/vehicle-care-management',
  '_url:repair-shop-pricing' : '/workshop-management-pricing',
  '_url:repair-shop-pricing-tables' : 'disabled', // Set to disabled so that it is not shown in the EN version. 
  '_url:dealership' : '/dealership-management-solution',
  '_url:dealership-features' : '/dealership-management-features',
  '_url:dealership-pricing' : '/dealership-management-pricing',
  '_url:leasing' : '/leasing-management-solution',
  '_url:leasing-features' : '/leasing-management-features',
  '_url:leasing-pricing' : '/leasing-management-pricing',
  '_url:sign-up' : '/sign-up',
  '_url:blog' : '/blog',
  '_url:404' : '/not-found',

  '_url:fleet-roi' : '/roi-calculator-fleet',
  '_url:logbook-roi' : '/roi-calculator-logbook',

  '_url:cookie-policy' : '/cookie-policy',
  '_url:privacy-policy' : '/privacy-policy',
  '_url:fleet-terms' : '/fleet-salgsbetingelser',

  '_url:use-cases' : '/connected-car-use-cases',

  // Landing pages

  // -- Landing Drive
  '_url:landing-driver' : '/drive-app',
  '_url:landing-driver-signup' : '/tilmeld-dig-drive-app',

  // -- Landing Fleet
  '_url:landing-fleet' : '/flaadestyring-tjeneste',
  '_url:landing-fleet-features' : '/flaadestyring-funktioner',
  '_url:landing-fleet-why' : '/flaadestyring-fordele',
  '_url:landing-fleet-pricing' : '/pris-flaadestyring',
  '_url:landing-fleet-signup' : '/tilmeld-dig-fleet',
  '_url:landing-fleet-demo' : '/demo-fleet',

  // -- Landing VC
  '_url:landing-vc' : '/vehicle-care-tjeneste',
  '_url:landing-vc-features' : '/vehicle-care-loesning',
  '_url:landing-vc-pricing' : '/vehicle-care-priser',
  '_url:landing-vc-signup' : '/vehicle-care-bliv-partner',

  // IDs in URL
  '_hash:main' : 'main',
  '_hash:vehicle-care' : 'vehicle-care',
  '_hash:fleet' : 'fleet',
  '_hash:driver-app' : 'driver-app',
  '_hash:for-you' : 'for-you',
  '_hash:for-customers' : 'for-customers',

  // IDs in About Us
  '_hash:company' : 'company',
  '_hash:vision' : 'vision',
  '_hash:partners' : 'partners',
  '_hash:contact-us' : 'contact-us',
  '_hash:join-us' : 'join-us',

  // IDs in Platform
  '_hash:saas-tools' : 'saas-tools',

  // IDs in Use cases
  '_hash:remote-monitoring' : 'remote-monitoring',
  '_hash:fleet-management' : 'fleet-management',
  '_hash:preventive-maintenace' : 'preventive-maintenace',
  '_hash:green-driving' : 'green-driving',
  '_hash:digital-communication' : 'digital-communication',
  '_hash:customer-satisfaction' : 'customer-satisfaction',
  '_hash:digital-documentation' : 'digital-documentation',
  '_hash:ev-ownership' : 'ev-ownership',

  // IDs in fleet features
  '_hash:different-vehicle-types' : 'different-vehicle-types',
  '_hash:fleet-dashboard' : 'fleet-dashboard',
  // '_hash:driver-app' : 'driver-app', // This one is duplicated above

  // IDs in Landing Drive
  '_hash:getting-started' : 'getting-started',
}