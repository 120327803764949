import React from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import i18n from '../../locale';
import Typography from '../Typography';
import Icon from './Icon';
import styles from './styles.module.sass';

export default ({ isLanding }) => (
  <>
    {isLanding
      ? <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className={clsx(styles.contactPitchContainer, styles.pitchNarrow)}>
          <Typography variant='h2' align='left' color='white'
            className={styles.contactPitchText}>
            <span className={styles.landingName}>{isLanding === 'vc' ? 'Vehicle Care' : isLanding}</span> er en digital tjeneste der drives af <a href="https://da.obiplus.com" target="_blank" rel="noreferrer" className={styles.obiHighlight}>OBI+</a>
          </Typography>
        </Grid>
      : null
    }
    <Grid item xs={12} sm={12} md={isLanding ? 6 : 12} lg={isLanding ? 8 : 12} xl={isLanding ? 8 : 12}
      className={styles.contactContainer}>
      <Typography variant='h4' align='left' color='white'
        className={styles.contactTopText}>
        {i18n('Contact')}
      </Typography>
      <Typography variant='subtitle2' align='left' weight='light' color='white'>
        {i18n('General inquiries')}:
      </Typography>
      <Typography variant='subtitle2' align='left' weight='light'  color='white'>
      {i18n('Write to')}: hello@obiplus.com
      </Typography>
      <Typography variant='subtitle2' align='left' weight='light' color='white'>
        {i18n('Telephone')}: +45 96 73 76 73
      </Typography>
      <br/>
      <Typography variant='subtitle2' align='left' weight='light'  color='white'>
        {i18n('Monday to Friday 09:00 to 16:00')}
      </Typography>
      <Typography variant='subtitle2' align='left' weight='light'  color='white'>
        {i18n('Address')} : Industrivej 28A, DK-9490 Pandrup, Denmark
      </Typography>
      <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}
        className={styles.iconContainer}
        justifyContent='flex-start' alignContent='flex-start' alignItems='flex-start'>
        <Icon name='youtube' url='https://www.youtube.com/channel/UCiUjVJA128QogrhqvuL71nw/'/>
        {/* <Icon name='twitter' url='https://twitter.com/obi_plus'/> */}
        <Icon name='linked-in' url="https://www.linkedin.com/company/obiplus/"/>
        <Icon name='facebook' url='https://www.facebook.com/obiplus.services/'/>
        <Icon name='email' noTarget={false} url='mailto:hello@obiplus.com'/>
      </Grid>
    </Grid>
    {isLanding
      ? <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className={clsx(styles.contactPitchContainer, styles.pitchWide)}>
          <Typography variant='h2' align='left' color='white'
            className={styles.contactPitchText}>
            <span className={styles.landingName}>{isLanding === 'vc' ? 'Vehicle Care' : isLanding}</span> er en digital tjeneste der drives af <a href="https://da.obiplus.com" target="_blank" rel="noreferrer" className={styles.obiHighlight}>OBI+</a>
          </Typography>
        </Grid>
      : null
    }
  </>
);