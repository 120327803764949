import React from 'react';
import clsx from 'clsx';
import { Link as GatsbyLink } from 'gatsby';
import Typography from '../../../Typography';
import styles from './styles.module.sass';

export default ({ items, expanded }) => (
  <ul className={styles.dropmenu}>
    {items.map(({ text, url }, index) => (
      <li className={clsx(
          styles.dropmenuItem,
          expanded ? styles.dropmenuItemExpanded : ''
        )}
        key={index}>
        <GatsbyLink to={url} 
          className={styles.dropmenuAnchor}>
          <Typography variant='subtitle1'
            align='left'
            color='white'
            className={clsx(
              styles.dropmenuText,
              expanded ? styles.dropmenuText : ''
            )}>
            {text}
          </Typography>
        </GatsbyLink>
      </li>
    ))}
  </ul>
)