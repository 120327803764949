import React from 'react';
import Item from './Item';
import styles from './styles.module.sass';

export default ({ items, page, url, isLanding }) => (
  <div className={styles.listContainer} role='menu'>
    {items.slice().reverse().map((el, key) => (
      <Item key={key}
        selected={el.name === page}
        isLanding={isLanding}
        {...el}/>
    ))}
  </div>
);