import React from 'react';
import clsx from 'clsx';
import { Link as GatsbyLink } from 'gatsby';
import Typography from '../../../Typography';
import styles from './styles.module.sass';

export default ({ menu, expanded }) => (
  <ul className={styles.dropmenu}>
    {menu.columns.map((column, index) => (
        <>
          <li className={clsx(
                styles.dropmenuItem,
                styles.dropmenuColumnTitle,
                expanded ? styles.dropmenuItemExpanded : ''
                )}
                key={`burger-column-${index}`}>
                <Typography variant='subtitle1'
                    align='left'
                    color='white'
                    className={clsx(
                    styles.dropmenuText,
                    expanded ? styles.dropmenuText : ''
                    )}>
                    {column.title}
                </Typography>
          </li>

          {column.items.map((item, subIndex) => (
            <li className={clsx(
                styles.dropmenuItem,
                expanded ? styles.dropmenuItemExpanded : ''
                )}
                key={`burger-item-${subIndex}`}>
                <GatsbyLink to={item.url} 
                className={styles.dropmenuAnchor}>
                  <Typography variant='subtitle1'
                      align='left'
                      color='white'
                      className={clsx(
                      styles.dropmenuText,
                      expanded ? styles.dropmenuText : ''
                      )}>
                      {item.title}
                  </Typography>
                </GatsbyLink>
            </li>
          ))}
        </>
    ))}
  </ul>
)